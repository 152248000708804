import React from 'react';

import { useTranslation } from 'react-i18next';

import { EditFormBase } from '../../components/form/EditFormBase';
import { MenuItems } from '../../components/layout/NavMenu';

export const ProductQuestionEdit: React.FC<{}> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <EditFormBase
        url="/claimProductQuestion"
        name="productQuestion"
        idParam="claimProductQuestionId"
        breadcrumbs={[MenuItems.ProductQuestions]}
        fields={[
          {
            label: 'Productgroup',
            field: 'productGroupId',
            required: true,
            col: 6,
            type: 'select',
            optionsUrl: '/productGroup',
            optionsValueField: 'id',
            optionsLabelField: 'name',
          },
          { label: 'Question', field: 'question', required: true, col: 12 },
          { label: 'Info', field: 'info', col: 12 },
          {
            label: 'Type',
            field: 'type',
            required: true,
            col: 6,
            type: 'select',
            options: [
              { value: 'text', label: t('Text') },
              { value: 'textarea', label: t('Multiline') },
              { value: 'number', label: t('Number') },
              { value: 'voltage', label: t('Voltage') },
              { value: 'year', label: t('Year') },
              { value: 'date', label: t('Date') },
              { value: 'boolean', label: t('Yes/No') },
            ],
          },
          { label: 'Order', field: 'order', required: true, type: 'number', col: 3 },
          { label: 'Required', field: 'required', type: 'boolean', col: 3 },
        ]}
      />
    </>
  );
};
