import React from 'react';

import { useTranslation } from 'react-i18next';

import { ViewFormBase } from '../../components/form/ViewFormBase';
import { MenuItems } from '../../components/layout/NavMenu';

export const UserDetail: React.FC<{}> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <ViewFormBase
        url="/user"
        name="user"
        idParam="userId"
        breadcrumbs={[MenuItems.Users]}
        fields={[
          { label: 'Name', field: 'name', col: 12 },
          { label: 'Email', field: 'email', col: 12 },
          {
            label: 'Role',
            field: 'role',
            col: 12,
            mapping: {
              customer: t('Customer'),
              administrator: t('Administrator'),
              superuser: t('Superuser'),
            },
          },
        ]}
      />
    </>
  );
};
