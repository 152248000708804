export enum Roles {
  superuser = 'superuser',
  administrator = 'administrator',
  customer = 'customer',
}

export interface IUser {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  email: string;
  role?: Roles;
  customerId?: number;
}
