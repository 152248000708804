import React from 'react';

import { Icon } from '@material-ui/core';
import { default as MuiBreadcrumbs } from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink } from 'react-router-dom';
import useReactRouter from 'use-react-router';

import { useTranslation } from 'react-i18next';
import { INavMenuLink } from './NavMenu';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1, 2),
    },
    link: {
      display: 'flex',
    },
    icon: {
      marginRight: theme.spacing(0.5),
    },
  }),
);

export const Breadcrumbs: React.FC<{
  items: INavMenuLink[];
}> = (props) => {
  const classes = useStyles({});
  const { match } = useReactRouter();
  const { t } = useTranslation();
  const firstItem: INavMenuLink = { name: 'Home', link: '/', icon: 'home' };
  const breadcrumbs =
    props.items && props.items[0].name === 'Dashboard' ? props.items : [firstItem, ...props.items];

  return (
    <Paper elevation={0} className={classes.root}>
      <MuiBreadcrumbs aria-label="Breadcrumb">
        {breadcrumbs.map((item, index) => {
          if (index === breadcrumbs.length - 1) {
            return (
              <Typography color="textPrimary" className={classes.link} key={item.name}>
                {item.icon ? (
                  item.fa ? (
                    <Icon className={`${classes.icon} fa fa-${item.icon}`} />
                  ) : (
                    <Icon className={classes.icon}>{item.icon}</Icon>
                  )
                ) : null}
                {t(item.name)}
              </Typography>
            );
          }
          // Add param to link if any
          let parsedLink = '';
          if (item.link) {
            for (const part of item.link.split('/')) {
              if (part.startsWith(':')) {
                parsedLink += (match.params as any)[part.substr(1)] + '/';
              } else {
                parsedLink += part + '/';
              }
            }
            parsedLink =
              parsedLink.substr(parsedLink.length - 1) === '/'
                ? parsedLink.substr(0, parsedLink.length - 1)
                : parsedLink;
          }
          return (
            <Link
              key={item.name}
              color="inherit"
              to={parsedLink}
              className={classes.link}
              component={RouterLink}
            >
              {item.icon ? (
                item.fa ? (
                  <Icon className={`${classes.icon} fa fa-${item.icon}`} />
                ) : (
                  <Icon className={classes.icon}>{item.icon}</Icon>
                )
              ) : null}
              {t(item.name)}
            </Link>
          );
        })}
      </MuiBreadcrumbs>
    </Paper>
  );
};
