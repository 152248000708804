import React, { createContext, useContext, useState } from 'react';

import { Icon, SnackbarContent } from '@material-ui/core';
import { amber, green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    close: {
      padding: theme.spacing(0.5),
    },
    message: {
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    info: {},
    success: {
      backgroundColor: green[600],
    },
    warning: {
      backgroundColor: amber[700],
    },
    error: {
      backgroundColor: theme.palette.error.dark,
    },
  }),
);

export interface ISnackbarState {
  messages: ISnackbarMessage[];
}

export interface ISnackbarContext extends ISnackbarState {
  info: (message: string) => void;
  success: (message: string) => void;
  warning: (message: string) => void;
  error: (message: string) => void;
}

export const SnackbarContext = createContext<ISnackbarContext>({
  messages: [],
  info: (message: string) => {
    // ..
  },
  success: (message: string) => {
    // ..
  },
  warning: (message: string) => {
    // ..
  },
  error: (message: string) => {
    // ..
  },
});

export const useSnackbar = () => {
  return useContext(SnackbarContext);
};

export const SnackbarContextProvider: React.FC<{}> = (props) => {
  const show = (type: string) => {
    return (message: string) => {
      setSnackbarState({
        messages: [...snackbarState.messages, { message, key: new Date().getTime(), type }],
      });
    };
  };

  const [snackbarState, setSnackbarState] = useState<ISnackbarState>({ messages: [] });
  return (
    <SnackbarContext.Provider
      value={{
        ...snackbarState,
        info: show('info'),
        success: show('success'),
        warning: show('warning'),
        error: show('error'),
      }}
    >
      {props.children}
    </SnackbarContext.Provider>
  );
};

export interface ISnackbarMessage {
  message: string;
  key: number;
  type: string;
}

const SnackbarOutlet: React.FC<{}> = (props) => {
  const snackbarContext = React.useContext(SnackbarContext);
  const [open, setOpen] = React.useState(false);
  const [messageInfo, setMessageInfo] = React.useState<ISnackbarMessage | undefined>(undefined);

  if (snackbarContext.messages.length > 0) {
    setMessageInfo(snackbarContext.messages.shift());
    setOpen(true);
  }

  const handleClose = (event: any | MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const classes = useStyles(props);
  return (
    <Snackbar
      key={messageInfo ? messageInfo.key : undefined}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      autoHideDuration={4000}
      onClose={handleClose}
      // onExited={handleExited}
    >
      <SnackbarContent
        className={(classes as any)[messageInfo ? messageInfo.type : 'info']}
        message={
          <span id="message-id" className={classes.message}>
            {messageInfo &&
              messageInfo.type === 'info' && <Icon className={classes.icon}>info</Icon>}
            {messageInfo &&
              messageInfo.type === 'success' && <Icon className={classes.icon}>check_circle</Icon>}
            {messageInfo &&
              messageInfo.type === 'warning' && <Icon className={classes.icon}>warning</Icon>}
            {messageInfo &&
              messageInfo.type === 'error' && <Icon className={classes.icon}>error</Icon>}
            {messageInfo ? messageInfo.message : undefined}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            className={classes.close}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
};

export default SnackbarOutlet;
