import React from 'react';

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { StylesProvider } from '@material-ui/styles';
import { BrowserRouter } from 'react-router-dom';

import { NavMenuProvider } from './components/layout/NavMenu';
import { Root } from './components/layout/Root';
import SnackbarOutlet, { SnackbarContextProvider } from './components/Snackbar';
import { AuthContextProvider } from './features/auth/Auth';
import { SettingsContextProvider } from './services/settings';

import './App.scss';

import { I18nextProvider } from 'react-i18next';
import i18n from './services/i18n';

const theme = createMuiTheme({
  typography: {
    fontFamily: ['Noto Sans'].join(','),
  },
  palette: {
    primary: { main: '#ef7000' },
    secondary: { main: '#4673c7' },
  },
});

export const App: React.FC = (props) => {
  return (
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={theme}>
          <StylesProvider injectFirst={true}>
            <SettingsContextProvider>
              <AuthContextProvider>
                <NavMenuProvider>
                  <SnackbarContextProvider>
                    <Root />
                    <SnackbarOutlet />
                  </SnackbarContextProvider>
                </NavMenuProvider>
              </AuthContextProvider>
            </SettingsContextProvider>
          </StylesProvider>
        </ThemeProvider>
      </I18nextProvider>
    </BrowserRouter>
  );
};

export default App;
