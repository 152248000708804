const theme = {
  colors: {
    success: {
      normal: '#4F8A10',
      light: '#DFF2BF',
      dark: '',
    },
    warning: {
      normal: '#fda300',
      light: '#ffd17e',
      dark: '',
    },
    error: {
      normal: '#D8000C',
      light: '#ffb1b1',
      dark: '',
    },
    primary: {
      normal: '#ef7000',
      light: '#b75600',
      dark: '',
    },
    secondary: {
      normal: '#4673c7',
      light: '#99b0dc',
      dark: '',
    },
    light: {
      normal: '#d6d6d6',
      light: '#ffffff',
      dark: '',
    },
    dark: {
      normal: '#6d6d6d',
      light: '#9d9d9d',
      dark: '',
    },
  },
};

export default theme;
