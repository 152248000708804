import React, { useState } from 'react';

import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { FormSelect } from '../../components/input/FormSelect';
import { useSnackbar } from '../../components/Snackbar';
import { Table } from '../../components/table/Table';
import { api } from '../../services/api';

import styles from './Scans.module.scss';

export const Scans: React.FC<{}> = (props) => {
  const { t } = useTranslation();
  const [openSwapDialog, setOpenSwapDialog] = useState<{ show: boolean; ids: number[] }>({
    show: false,
    ids: [],
  });
  const snackbar = useSnackbar();
  const [assignCustomer, setAssignCustomer] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<number>(0);

  const assignScansToCustomer = async (customerId: number, scanIds: number[]) => {
    if (!customerId) {
      return;
    }

    setLoading(true);

    const result = await api.post('/scan/assign', {
      customerId,
      scanIds,
    });

    setLoading(false);

    if (result.status !== 200) {
      snackbar.error('Scans toewijzen aan klant mislukt.');
      return;
    }

    snackbar.success('Scans succesvol toegewezen aan klant.');

    setOpenSwapDialog({ show: false, ids: [] });

    setRefresh(refresh + 1);
  };

  return (
    <>
      <Table
        url={'/scan'}
        headers={[]}
        include="customer"
        defaultSortColumn="scans.createdAt"
        title={'Scans'}
        disableAdd={true}
        showSelect={true}
        columns={[
          { id: 'customer.wetacCustomerId', label: 'Customer ID' },
          { id: 'customer.name', label: 'Customer' },
          {
            id: 'type',
            label: 'Type',
            mapping: { 1: t('Incoming'), 2: t('Outgoing') },
            filterField: 'scans.type',
            filters: [{ value: '1', name: t('Incoming') }, { value: '2', name: t('Outgoing') }],
          },
          { id: 'code', label: 'Code' },
          {
            id: 'createdAt',
            label: 'Created At',
            type: 'date',
            filterField: 'custom',
            filters: [{ value: 'incoming_without_outgoing', name: t('Incoming without outgoing') }],
          },
        ]}
        selectActions={(ids: number[]) => (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setOpenSwapDialog({ show: true, ids });
            }}
            size="small"
          >
            <Icon>swap_horiz</Icon>
          </Button>
        )}
        refreshIncrement={refresh}
      />
      <Dialog
        open={openSwapDialog.show}
        onClose={() => {
          setOpenSwapDialog({ show: false, ids: [] });
        }}
      >
        <DialogTitle>{t('scans.re-assign.title')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('scans.re-assign.message')}</DialogContentText>
          {!loading && (
            <FormSelect
              field={{
                field: '',
                label: 'Customer',
                optionsUrl: '/customer',
                optionsValueField: 'id',
                optionsLabelField: 'name',
              }}
              value={assignCustomer}
              onChange={(id: number) => setAssignCustomer(id)}
              validation={{ valid: true, dirty: false, message: '' }}
            />
          )}
          {loading && <CircularProgress className={styles.progress} color="inherit" />}
        </DialogContent>
        {!loading && (
          <DialogActions>
            <Button
              onClick={() => {
                setOpenSwapDialog({ show: false, ids: [] });
              }}
              color="primary"
            >
              {t('Cancel')}
            </Button>
            <Button
              onClick={() => {
                if (assignCustomer) {
                  assignScansToCustomer(assignCustomer, openSwapDialog.ids);
                }
              }}
              color="primary"
              autoFocus={true}
            >
              {t('OK')}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};
