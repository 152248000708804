import React, { useEffect, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Icon,
  Paper,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useReactRouter from 'use-react-router';
import validator from 'validator';

import { api } from '../../services/api';
import { AppDateTime } from '../Date';
import { FormComponent, IFormInput, IValidationState } from '../input/FormComponent';
import { Breadcrumbs } from '../layout/Breadcrumbs';
import { INavMenuLink } from '../layout/NavMenu';
import { useSnackbar } from '../Snackbar';

import styles from './EditFormBase.module.scss';

const Form: React.FC<{}> = (props) => {
  return (
    <Grid container={true} spacing={1} className={styles.form}>
      {props.children}
    </Grid>
  );
};

export const EditFormBase: React.FC<{
  url: string;
  name: string;
  link?: string;
  idParam: string;
  fields: IFormInput[];
  breadcrumbs?: INavMenuLink[];
  showDelete?: boolean;
}> = (props) => {
  const { match, history } = useReactRouter();
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const [entity, setEntity] = useState<any>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [fieldValidations, setFieldValidations] = useState<IValidationState[]>([]);
  const isNew = (match.params as any)[props.idParam] === 'new';

  let returnUrl: string;
  for (const queryParam of window.location.search.substring(1).split('&')) {
    if (queryParam.split('=')[0] === 'return') {
      returnUrl = queryParam.split('=')[1];
    }
  }

  const setValidationByField = (field: string, dirty: true, valid: false, message: string) => {
    // Get field index
    const index = props.fields.findIndex((f) => f.field === field);
    if (index < 0) {
      return;
    }
    fieldValidations[index] = { dirty, valid, message };
    setFieldValidations({ ...fieldValidations });
  };

  useEffect(() => {
    // Init object with hidden value
    const objHiddenValues: any = {};
    for (const field of props.fields) {
      if (field.type === 'hidden') {
        objHiddenValues[field.field] = field.value;
      }
    }

    const getEntity = async () => {
      const result = await api.get(props.url + '/' + (match.params as any)[props.idParam]);
      if (result) {
        setEntity({ ...result.data.data, ...objHiddenValues });
      }
    };

    if (isNew) {
      setEntity({ ...objHiddenValues });
    } else {
      getEntity();
    }
  }, []);

  const onSaveClick = async () => {
    // Validate all inputs
    if (
      props.fields.filter((field, index) => !validateInput(field, entity[field.field], index))
        .length
    ) {
      snackbar.warning(t('validation.check_input'));
      return;
    }

    const result = isNew
      ? await api.post(props.url, entity)
      : await api.put(props.url + '/' + entity.id, entity);

    if (result && result.status === 200) {
      if (returnUrl) {
        history.push(returnUrl);
        return;
      }
      props.link ? history.push(props.link) : history.push(props.url + '/' + result.data.data.id);
      snackbar.success(t('Successfully saved {{text}}', { text: t(props.name) }));
    } else {
      // Check validation errors
      if (result && result.status === 400 && result.data && result.data.fields) {
        for (const key of Object.keys(result.data.fields)) {
          const fieldErrors = result.data.fields[key] as string[];
          if (fieldErrors && fieldErrors.length) {
            setValidationByField(key, true, false, fieldErrors.map((m) => t(m)).join(', '));
          }
        }
      }

      snackbar.error(t('Error occured while saving {{text}}', { text: t(props.name) }));
    }
  };

  const onCancelClick = () => {
    if (returnUrl) {
      history.push(returnUrl);
      return;
    }

    props.link
      ? history.push(props.link)
      : isNew
        ? history.push(props.url + 's')
        : history.push(props.url + '/' + (match.params as any)[props.idParam]);
  };

  const onDeleteClick = () => {
    setOpenDeleteDialog(true);
  };

  const deleteEntity = async () => {
    const result = await api.delete(props.url + '/' + (match.params as any)[props.idParam]);
    if (result && result.data === 'OK') {
      snackbar.success(t('form.delete_success', { text: t(entity.name || props.name) }));
      if (returnUrl) {
        history.push(returnUrl);
        return;
      }
      props.link ? history.push(props.link) : history.push(props.url + 's');
    } else {
      snackbar.error(t('form.delete_fail', { text: t(entity.name || props.name) }));
    }
  };

  const validateInput = (field: IFormInput, value: any, index: number): boolean => {
    // Validate input
    const newValidationState = { valid: true, dirty: true, message: '' };
    if (field.required && !value) {
      newValidationState.valid = false;
      newValidationState.message = t('validation.required');
    }
    if (field.type === 'email' && value && !validator.isEmail(value)) {
      newValidationState.valid = false;
      newValidationState.message = t('validation.invalid_email');
    }

    fieldValidations[index] = newValidationState;
    setFieldValidations(fieldValidations);

    return newValidationState.valid;
  };

  if (!entity) {
    return null;
  }

  return (
    <>
      <Dialog
        open={openDeleteDialog}
        onClose={() => {
          setOpenDeleteDialog(false);
        }}
      >
        <DialogTitle>
          {t('form.delete_confirmation_title', { text: t(entity.name || props.name) })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('form.delete_confirmation_text', { text: t(entity.name || props.name) })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDeleteDialog(false);
            }}
            color="primary"
          >
            {t('Cancel')}
          </Button>
          <Button
            onClick={() => {
              setOpenDeleteDialog(false);
              deleteEntity();
            }}
            color="primary"
            autoFocus={true}
          >
            {t('OK')}
          </Button>
        </DialogActions>
      </Dialog>
      <Breadcrumbs
        items={[
          ...(props.breadcrumbs || []),
          { name: (isNew ? t('Add') : t('Edit')) + ' ' + t(entity.name || props.name) },
        ]}
      />
      <Paper className={styles.paper}>
        <div className={styles.header}>
          <Typography variant="h6" className={styles.title}>
            {isNew ? t('Add') : t('Edit')} {t(entity.name || props.name)}
          </Typography>
        </div>
        <div className={styles.content}>
          <Form>
            {props.fields
              .filter((field) => !field.show || field.show === (isNew ? 'new' : 'edit'))
              .map((field, index) => {
                return (
                  <FormComponent
                    key={index}
                    field={field}
                    value={entity[field.field]}
                    validation={
                      fieldValidations[index] || { valid: true, dirty: false, message: '' }
                    }
                    onChange={(value) => {
                      const updatedEntity = { ...entity };
                      updatedEntity[field.field] = value;
                      setEntity(updatedEntity);
                      validateInput(field, value, index);
                    }}
                  />
                );
              })}
          </Form>
        </div>
        <div className={styles.footer}>
          <div>
            <Button variant="contained" color="primary" onClick={onSaveClick}>
              {t('Save')}
            </Button>
            <Button variant="contained" color="default" onClick={onCancelClick}>
              {t('Cancel')}
            </Button>
            {props.showDelete && (
              <Button variant="contained" color="default" onClick={onDeleteClick}>
                <Icon>delete</Icon>
              </Button>
            )}
          </div>
          <Typography variant="caption" id="tableTitle" className={styles.created}>
            {t('Created')}: <AppDateTime value={entity.createdAt} />
            {entity.updatedAt > entity.createdAt ? (
              <>
                , {t('Last modified')}: <AppDateTime value={entity.updatedAt} />
              </>
            ) : null}
          </Typography>
        </div>
      </Paper>
    </>
  );
};
