import React from 'react';

import useReactRouter from 'use-react-router';

import { Table } from '../../components/table/Table';
import theme from '../../utils/Theme';

export const Claims: React.FC<{}> = (props) => {
  const { history } = useReactRouter();
  return (
    <>
      <Table
        url={'/claim'}
        headers={[]}
        include="claimStatus,customer"
        title={'Claims'}
        disableAdd={true}
        columns={[
          { id: 'claimNr', label: 'Claim #' },
          {
            id: 'claimStatus.name',
            label: 'Status',
            colorField: 'claimStatus.code',
            colors: [
              { value: 'in_progress', color: theme.colors.warning.light },
              { value: 'rejected', color: theme.colors.error.light },
              { value: 'processed', color: theme.colors.success.light },
            ],
            filterField: 'claimStatus.code',
            filters: [
              { value: 'new', name: 'Nieuw' },
              { value: 'in_progress', name: 'In behandeling' },
              { value: 'rejected', name: 'Afgekeurd' },
              { value: 'processed', name: 'Afgehandeld' },
            ],
          },
          { id: 'customer.name', label: 'Customer' },
          { id: 'requesterName', label: 'Requester' },
          { id: 'jobTitle', label: 'Job title' },
          { id: 'companyName', label: 'Company' },
          { id: 'companyLocation', label: 'Location' },
          { id: 'createdAt', label: 'Created At', type: 'date' },
        ]}
        onClick={(claim: any) => {
          history.push(`/customer/${claim.customerId}/claim/${claim.id}`);
        }}
      />
    </>
  );
};
