import React, { useState, useEffect } from 'react';

import useReactRouter from 'use-react-router';

import { ViewFormBase } from '../../components/form/ViewFormBase';
import { MenuItems } from '../../components/layout/NavMenu';
import { Table } from '../../components/table/Table';
import { api } from '../../services/api';
import { toDutchString } from '../../utils/Extensions';

export const ClaimProductDetail: React.FC<{}> = (props) => {
  const { match } = useReactRouter();
  const [claimProduct, setClaimProduct] = useState<any>({});

  useEffect(() => {
    const getClaimProduct = async () => {
      const result = await api.get(`/claimProduct/${(match.params as any).claimProductId}`, {
        pageSize: 99,
      });
      if (result) {
        setClaimProduct(result.data.data);
      }
    };

    getClaimProduct();
  }, []);

  return (
    <>
      <ViewFormBase
        url="/claimProduct"
        include={'productGroup'}
        name="Claim product"
        idParam="claimProductId"
        disableEditing={true}
        disableDelete={true}
        breadcrumbs={[MenuItems.CustomerDetail, MenuItems.ClaimDetail]}
        fields={[
          { field: 'code', label: 'Product code', col: 12 },
          { field: 'productGroup.name', label: 'Product group', col: 6 },
          { field: 'type', label: 'Type', col: 6 },
          { field: 'orderNumber', label: 'Order Number', col: 6 },
          { field: 'productionDate', label: 'Production Date', col: 6, type: 'date' },
          { field: 'sequenceNumber', label: 'Sequence Number', col: 6 },
        ]}
      />
      {claimProduct &&
        claimProduct.code && (
          <Table
            url={`/customer/${(match.params as any).customerId}/registration`}
            queryAndFilters={[{ field: 'registrations.code', value: claimProduct.code }]}
            include={'productGroup'}
            defaultSortColumn={'productSetNr'}
            defaultSortOrder={'desc'}
            headers={[]}
            title={'Registrations'}
            showSelect={true}
            columns={[
              { id: 'code', label: 'Product code' },
              // { id: 'productSetNr', label: 'Product set' },
              { id: 'productGroup.name', label: 'Product group' },
              { id: 'type', label: 'Type' },
              { id: 'orderNumber', label: 'Order Number' },
              { id: 'productionDate', label: 'Production Date', type: 'date' },
              { id: 'sequenceNumber', label: 'Sequence Number' },
              { id: 'createdAt', label: 'Created At', type: 'date' },
            ]}
          />
        )}
      <Table
        url={`/claimProduct/${(match.params as any).claimProductId}/answer`}
        defaultSortColumn={'type'}
        defaultSortOrder={'desc'}
        disableAdd={true}
        headers={[]}
        title={'Product answers'}
        onClick={() => {}}
        rowsPerPage={50}
        columns={[
          { id: 'question', label: 'Question' },
          {
            id: 'value',
            label: 'Answer',
            type: 'custom',
            component: (answer: any) => {
              if (!answer) {
                return '';
              }
              switch (answer.type) {
                case 'year':
                  return answer.value ? new Date(answer.value).getFullYear() : '-';
                case 'boolean':
                  return answer.value && answer.value.toLowerCase() !== 'false' ? 'Ja' : 'Nee';
                case 'date':
                  return toDutchString(new Date(answer.value));
                case 'voltage':
                  return answer.value + ' Volt';
                default:
                  return answer.value;
              }
            },
          },
        ]}
      />
    </>
  );
};
