import React from 'react';

import { Breadcrumbs } from '../../components/layout/Breadcrumbs';
import { MenuItems } from '../../components/layout/NavMenu';
import { Table } from '../../components/table/Table';

export const ProductQuestions: React.FC<{}> = (props) => {
  return (
    <>
      <Breadcrumbs items={[MenuItems.ProductQuestions]} />
      <Table
        url={'/claimProductQuestion'}
        headers={[]}
        title={'Productquestions'}
        defaultSortColumn={'order'}
        defaultSortOrder={'asc'}
        columns={[
          { id: 'question', label: 'Question' },
          { id: 'type', label: 'Type' },
          { id: 'order', label: 'Order' },
          { id: 'createdAt', label: 'Created At', type: 'date' },
          { id: 'updatedAt', label: 'Updated At', type: 'date' },
        ]}
      />
    </>
  );
};
