import { TextField } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IFormInput, IValidationState } from './FormComponent';

import styles from './FormText.module.scss';

export const FormText: React.FC<{
  field: IFormInput;
  value: any;
  validation: IValidationState;
  onChange?: (value: any) => void;
  multiline?: boolean;
  number?: boolean;
  password?: boolean;
}> = (props) => {
  const { t } = useTranslation();
  return (
    <TextField
      error={!props.validation.valid}
      helperText={!props.validation.valid ? props.validation.message : ''}
      className={styles.input}
      label={t(props.field.label || '') + (props.field.required ? ' *' : '')}
      placeholder={props.field.label}
      margin="normal"
      multiline={props.multiline}
      value={props.value || ''}
      onChange={(event) => {
        if (props.onChange) {
          if (props.number) {
            props.onChange(parseInt(event.target.value, 10));
          } else {
            props.onChange(event.target.value);
          }
        }
      }}
      type={props.number ? 'number' : props.password ? 'password' : undefined}
    />
  );
};
