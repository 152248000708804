import React from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import { default as MuiTableHead } from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { useTranslation } from 'react-i18next';

import { ITableColumn } from './Table';

const useTableHeadStyles = makeStyles((theme) => ({
  column: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

export const TableHead: React.FC<{
  numSelected: number;
  onRequestSort: (event: any, property: any) => void;
  onSelectAllClick: (event: any) => void;
  order?: 'desc' | 'asc';
  orderBy: string;
  rowCount: number;
  columns: ITableColumn[];
  showSelect?: boolean;
}> = (props) => {
  const { t } = useTranslation();
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property: any) => (event: any) => {
    onRequestSort(event, property);
  };
  const classes = useTableHeadStyles({});

  return (
    <MuiTableHead>
      <TableRow>
        {props.showSelect ? (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': t('Select all') }}
            />
          </TableCell>
        ) : null}
        {props.columns.filter((c) => c.hide !== true).map((column) => (
          <TableCell
            key={column.id}
            align={['numeric', 'date', 'currency'].includes(column.type || '') ? 'right' : 'left'}
            padding={column.disablePadding ? 'none' : 'default'}
            className={classes.column}
            sortDirection={orderBy === column.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === column.id}
              direction={order}
              onClick={createSortHandler(column.id)}
            >
              {t(column.label)}
            </TableSortLabel>
          </TableCell>
        ))}
        {/* <TableCell padding="checkbox" /> */}
      </TableRow>
    </MuiTableHead>
  );
};
