import axios, { AxiosRequestConfig } from 'axios';
import { getI18n } from 'react-i18next';

import { IAuthState } from '../features/auth/Auth';
import config from '../utils/Config';

const axi = axios.create({
  baseURL: config.API_URL,
});

const getDefaultRequestConfig = (): AxiosRequestConfig => {
  const requestConfig: AxiosRequestConfig = {
    headers: {},
  };

  const authStateJson = localStorage.getItem('AUTH_STATE');
  if (authStateJson) {
    const authState = JSON.parse(authStateJson) as IAuthState;
    if (authState.isAuthenticated) {
      requestConfig.headers = {
        Authorization: 'Bearer ' + authState.token,
      };
    }
  }

  requestConfig.headers = {
    ...requestConfig.headers,
    'x-wetac-language': getI18n().language || 'en',
  };

  return requestConfig;
};

export interface IApiFilter {
  page?: number;
  pageSize?: number;
  sortBy?: string;
  sortOrder?: 'asc' | 'desc';
  search?: string;
  include?: string;
  select?: string[];
  andFilters?: Array<{ field: string; value: string }>;
  orFilters?: Array<{ field: string; value: string }>;
}

export const api = {
  get: async (url: string, filters?: IApiFilter) => {
    try {
      let query = '';
      if (filters) {
        query = '?';
        for (const [key, val] of Object.entries(filters)) {
          if (key === 'andFilters' || key === 'orFilters') {
            if (key === 'andFilters' && filters.andFilters) {
              query += `_${key}=${filters.andFilters
                .map((f) => f.field + ':' + f.value)
                .join(',')}&`;
            } else if (key === 'orFilters' && filters.orFilters) {
              query += `_${key}=${filters.orFilters
                .map((f) => f.field + ':' + f.value)
                .join(',')}&`;
            }
          } else if (key === 'select' && val) {
            query += `_${key}=${val.join(',')}&`;
          } else if (val) {
            query += `_${key}=${val}&`;
          }
        }
      }
      const result = await axi.get(url + query, getDefaultRequestConfig());
      return result;
    } catch (err) {
      // tslint:disable-next-line:no-console
      console.error(err);
      return err.response;
    }
  },
  post: async (url: string, data: any) => {
    try {
      const result = await axi.post(url, data, getDefaultRequestConfig());
      return result;
    } catch (err) {
      // tslint:disable-next-line:no-console
      console.error(err);
      return err.response;
    }
  },
  put: async (url: string, data: any) => {
    try {
      const result = await axi.put(url, data, getDefaultRequestConfig());
      return result;
    } catch (err) {
      // tslint:disable-next-line:no-console
      console.error(err);
      return err.response;
    }
  },
  delete: async (url: string) => {
    try {
      const result = await axi.delete(url, getDefaultRequestConfig());
      return result;
    } catch (err) {
      // tslint:disable-next-line:no-console
      console.error(err);
      return err.response;
    }
  },
};
