import React from 'react';

import { useTranslation } from 'react-i18next';

import { EditFormBase } from '../../components/form/EditFormBase';
import { MenuItems } from '../../components/layout/NavMenu';

export const ScanEdit: React.FC<{}> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <EditFormBase
        url="/scan"
        link="/scans"
        name="scan"
        idParam="scanId"
        breadcrumbs={[MenuItems.Scans]}
        fields={[
          {
            label: 'Customer',
            field: 'customerId',
            required: true,
            col: 12,
            type: 'select',
            optionsUrl: '/customer',
            optionsValueField: 'id',
            optionsLabelField: 'name',
          },
          {
            label: 'Type',
            field: 'type',
            col: 12,
            type: 'select',
            options: [{ label: t('Incoming'), value: 1 }, { label: t('Outgoing'), value: 2 }],
          },
          { label: 'Code', field: 'code', col: 12 },
        ]}
      />
    </>
  );
};
