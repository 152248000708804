import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import useReactRouter from 'use-react-router';

import { ViewFormBase } from '../../components/form/ViewFormBase';
import { MenuItems } from '../../components/layout/NavMenu';
import { Table } from '../../components/table/Table';
import theme from '../../utils/Theme';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
} from '@material-ui/core';
import { useSnackbar } from '../../components/Snackbar';
import { api } from '../../services/api';
import { FormSelect } from '../../components/input/FormSelect';

import styles from './Scans.module.scss';
import { ICustomer } from '../../models/ICustomer';

export const CustomerDetail: React.FC<{}> = (props) => {
  const { match, history } = useReactRouter();
  const { t } = useTranslation();

  const [showCreateAccountDialog, setShowCreateAccountDialog] = useState<boolean>(false);
  const [showRemoveAccountDialog, setShowRemoveAccountDialog] = useState<boolean>(false);
  const [openSwapDialog, setOpenSwapDialog] = useState<{ show: boolean; ids: number[] }>({
    show: false,
    ids: [],
  });
  const snackbar = useSnackbar();
  const [assignCustomer, setAssignCustomer] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<number>(0);
  const [customer, setCustomer] = useState<ICustomer>();

  const getCustomer = async () => {
    const result = await api.get(`/customer/${(match.params as any).customerId}`, {
      pageSize: 99,
      include: 'users',
    });
    if (result) {
      setCustomer(result.data.data);
    }
  };

  const assignScansToCustomer = async (customerId: number, scanIds: number[]) => {
    if (!customerId) {
      return;
    }

    setLoading(true);

    const result = await api.post('/scan/assign', {
      customerId,
      scanIds,
    });

    setLoading(false);

    if (result.status !== 200) {
      snackbar.error('Scans toewijzen aan klant mislukt.');
      return;
    }

    snackbar.success('Scans succesvol toegewezen aan klant.');

    setOpenSwapDialog({ show: false, ids: [] });

    setRefresh(refresh + 1);
  };

  const createCustomerAccount = async () => {
    try {
      const result = await api.post(`/customer/${+(match.params as any).customerId}/user`, {
        customerId: +(match.params as any).customerId,
      });

      if (result.status !== 200) {
        if (result && result.data && result.data.fields) {
          let reason = '';
          for (const key of Object.keys(result.data.fields)) {
            reason += result.data.fields[key] + '\n';
          }
          snackbar.error('Klant account aanmaken mislukt. Reden: \n' + reason);
        } else {
          snackbar.error('Klant account aanmaken mislukt.');
        }

        return;
      }

      if (result.data && result.data.status !== 'OK') {
        snackbar.warning('Klant account niet aangemaakt. Reden: ' + result.data.status);
      } else {
        snackbar.success(
          'Klant account succesvol aangemaakt. Klant heeft een e-mail met de inloggegevens ontvangen.',
        );
      }
    } finally {
      setShowCreateAccountDialog(false);
    }
  };

  const removeCustomerAccount = async () => {
    try {
      if (customer && customer.users && customer.users.length) {
        const result = await api.delete(`/user/${customer.users[0].id}`);

        if (result.status !== 200) {
          if (result && result.data && result.data.fields) {
            let reason = '';
            for (const key of Object.keys(result.data.fields)) {
              reason += result.data.fields[key] + '\n';
            }
            snackbar.error('Klant account verwijderen mislukt. Reden: \n' + reason);
          } else {
            snackbar.error('Klant account verwijderen mislukt.');
          }

          return;
        }

        if (result.data && result.data !== 'OK') {
          snackbar.warning('Klant account niet verwijderd. Reden: ' + result.data.status);
        } else {
          snackbar.success('Klant account succesvol verwijderd.');

          getCustomer();
        }
      }
    } finally {
      setShowRemoveAccountDialog(false);
    }
  };

  useEffect(() => {
    getCustomer();
  }, []);

  return (
    <>
      <ViewFormBase
        url="/customer"
        name="customer"
        idParam="customerId"
        breadcrumbs={[MenuItems.Customers]}
        fields={[
          { label: 'Address', field: 'address', col: 12 },
          // { label: 'Housenumber', field: 'houseNumber', col: 2 },
          // { label: 'Addition', field: 'houseNumberAddition', col: 4 },
          { label: 'Postal code', field: 'postalCode', col: 3 },
          { label: 'City', field: 'city', col: 3 },
          { label: 'Country', field: 'country', col: 6 },
          { label: 'Contact person', field: 'contact', col: 6 },
          { label: 'Email', field: 'email', col: 6 },
          { label: 'Phone', field: 'phone', col: 6 },
          { label: 'Customer ID', field: 'wetacCustomerId', col: 6 },
        ]}
      >
        {customer && customer.users && customer.users.length ? (
          <Button
            variant="contained"
            color="default"
            onClick={() => {
              setShowRemoveAccountDialog(true);
            }}
            title="Klant account verwijderen"
          >
            <Icon color="error">person_remove</Icon>
          </Button>
        ) : (
          <Button
            variant="contained"
            color="default"
            onClick={() => {
              setShowCreateAccountDialog(true);
            }}
            title="Klant account aanmaken"
          >
            <Icon>person_add</Icon>
          </Button>
        )}
      </ViewFormBase>
      <Table
        url={`/customer/${(match.params as any).customerId}/registration`}
        include={'productGroup'}
        defaultSortColumn={'productSetNr'}
        defaultSortOrder={'desc'}
        headers={[]}
        title={'Registrations'}
        showSelect={true}
        columns={[
          { id: 'code', label: 'Product code' }, // { id: 'productSetNr', label: 'Product set' },
          { id: 'productGroup.name', label: 'Product group' },
          { id: 'type', label: 'Type' },
          // { id: 'orderNumber', label: 'Order Number' },
          // { id: 'productionDate', label: 'Production Date', type: 'date' },
          { id: 'sequenceNumber', label: 'Sequence Number' },
          { id: 'createdAt', label: 'Created At', type: 'date' },
        ]}
      />
      <Table
        url={`/customer/${(match.params as any).customerId}/claim`}
        headers={[]}
        include="claimStatus,customer"
        title={'Claims'}
        showSelect={true}
        columns={[
          {
            id: 'claimStatus.name',
            label: 'Status',
            colorField: 'claimStatus.code',
            colors: [
              { value: 'in_progress', color: theme.colors.warning.light },
              { value: 'rejected', color: theme.colors.error.light },
              { value: 'processed', color: theme.colors.success.light },
            ],
            filterField: 'claimStatus.code',
            filters: [
              { value: 'new', name: 'Nieuw' },
              { value: 'in_progress', name: 'In behandeling' },
              { value: 'rejected', name: 'Afgekeurd' },
              { value: 'processed', name: 'Afgehandeld' },
            ],
          },
          { id: 'customer.name', label: 'Customer' },
          { id: 'requesterName', label: 'Requester' },
          { id: 'jobTitle', label: 'Job title' },
          { id: 'companyName', label: 'Company' },
          { id: 'companyLocation', label: 'Location' },
          { id: 'createdAt', label: 'Created At', type: 'date' },
        ]}
      />
      <Table
        url={`/customer/${(match.params as any).customerId}/scan`}
        //url={'/scan'}
        headers={[]}
        include="customer"
        title={'Scans'}
        disableAdd={true}
        showSelect={true}
        columns={[
          { id: 'customer.wetacCustomerId', label: 'Customer ID', hide: true },
          { id: 'customer.name', label: 'Customer', hide: true },
          {
            id: 'type',
            label: 'Type',
            mapping: { 1: t('Incoming'), 2: t('Outgoing') },
            filterField: 'scans.type',
            filters: [{ value: '1', name: t('Incoming') }, { value: '2', name: t('Outgoing') }],
          },
          { id: 'code', label: 'Code' },
          { id: 'createdAt', label: 'Created At', type: 'date' },
        ]}
        defaultSortColumn="createdAt"
        defaultSortOrder="desc"
        selectActions={(ids: number[]) => (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setOpenSwapDialog({ show: true, ids });
            }}
            size="small"
          >
            <Icon>swap_horiz</Icon>
          </Button>
        )}
        refreshIncrement={refresh}
        onClick={(scan: any) => {
          history.push(`/scan/${scan.id}`);
        }}
      />
      <Dialog
        open={openSwapDialog.show}
        onClose={() => {
          setOpenSwapDialog({ show: false, ids: [] });
        }}
      >
        <DialogTitle>{t('scans.re-assign.title')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('scans.re-assign.message')}</DialogContentText>
          {!loading && (
            <FormSelect
              field={{
                field: '',
                label: 'Customer',
                optionsUrl: '/customer',
                optionsValueField: 'id',
                optionsLabelField: 'name',
              }}
              value={assignCustomer}
              onChange={(id: number) => setAssignCustomer(id)}
              validation={{ valid: true, dirty: false, message: '' }}
            />
          )}
          {loading && <CircularProgress className={styles.progress} color="inherit" />}
        </DialogContent>
        {!loading && (
          <DialogActions>
            <Button
              onClick={() => {
                setOpenSwapDialog({ show: false, ids: [] });
              }}
              color="primary"
            >
              {t('Cancel')}
            </Button>
            <Button
              onClick={() => {
                if (assignCustomer) {
                  assignScansToCustomer(assignCustomer, openSwapDialog.ids);
                }
              }}
              color="primary"
              autoFocus={true}
            >
              {t('OK')}
            </Button>
          </DialogActions>
        )}
      </Dialog>
      <Dialog
        open={showCreateAccountDialog}
        onClose={() => {
          setShowCreateAccountDialog(false);
        }}
      >
        <DialogTitle>{t('Klant account aanmaken')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t(
              'Klik op OK om een klant account aan te maken. De klant ontvangt een e-mail met daarin de inloggegevens. Het e-mailadres moet in de klantgegevens zijn ingevuld.',
            )}
          </DialogContentText>
        </DialogContent>
        {!loading && (
          <DialogActions>
            <Button
              onClick={() => {
                setShowCreateAccountDialog(false);
              }}
              color="primary"
            >
              {t('Cancel')}
            </Button>
            <Button
              onClick={() => {
                createCustomerAccount();
              }}
              color="primary"
              autoFocus={true}
            >
              {t('OK')}
            </Button>
          </DialogActions>
        )}
      </Dialog>
      <Dialog
        open={showRemoveAccountDialog}
        onClose={() => {
          setShowRemoveAccountDialog(false);
        }}
      >
        <DialogTitle>{t('Klant account verwijderen')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t(
              'Weet u zeker dat u het inlogaccount van de klant wilt verwijderen? De klant kan dan niet meer inloggen met de reeds bestaande inloggegevens. Er worden geen klantgegevens verwijderd.',
            )}
          </DialogContentText>
        </DialogContent>
        {!loading && (
          <DialogActions>
            <Button
              onClick={() => {
                setShowRemoveAccountDialog(false);
              }}
              color="primary"
            >
              {t('Cancel')}
            </Button>
            <Button
              onClick={() => {
                removeCustomerAccount();
              }}
              color="primary"
              autoFocus={true}
            >
              {t('OK')}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};
