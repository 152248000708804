import React, { createContext, useContext, useState } from 'react';
import useReactRouter from 'use-react-router';

import {
  Divider,
  Drawer,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link, LinkProps } from 'react-router-dom';

import styles from './NavMenu.module.scss';
import { Roles } from '../../models/IUser';
import { AuthContext } from '../../features/auth/Auth';

export const NavMenuContext = createContext({
  isNavMenuOpen: true,
  toggleNavMenu: () => {
    // ..
  },
});

export const NavMenuProvider: React.FC<{}> = (props) => {
  const [isNavMenuOpen, setNavMenuOpen] = useState(true);
  return (
    <NavMenuContext.Provider
      value={{
        isNavMenuOpen,
        toggleNavMenu: () => {
          setNavMenuOpen(!isNavMenuOpen);
        },
      }}
    >
      {props.children}
    </NavMenuContext.Provider>
  );
};

export interface INavMenuLink {
  name: string;
  icon?: string;
  link?: string;
  fa?: boolean;
}

export const MenuItems = {
  Home: { name: 'Home', icon: 'home', link: '/' },
  Dashboard: { name: 'Dashboard', icon: 'home', link: '/dashboard' },

  ClaimEdit: { name: 'Claim', icon: 'warning', link: '/customer/:customerId/claim/:claimId/edit' },
  ClaimDetail: { name: 'Claim', icon: 'warning', link: '/customer/:customerId/claim/:claimId' },
  ClaimProductDetail: {
    name: 'Claim',
    icon: 'warning',
    link: '/customer/:customerId/claim/:claimId/product/:claimProductId',
  },
  Claims: { name: 'Claims', icon: 'warning', link: '/claims' },

  CustomerEdit: {
    name: 'Edit customer',
    link: '/customer/:customerId/edit',
  },
  CustomerDetail: {
    name: 'Customer',
    icon: 'assignment_ind',
    link: '/customer/:customerId',
  },
  Customers: {
    name: 'Customers',
    icon: 'assignment_ind',
    link: '/customers',
  },

  RegistrationEdit: {
    name: 'Registration',
    icon: 'assignment',
    link: '/customer/:customerId/registration/:registrationId',
  },
  Registrations: { name: 'Registrations', icon: 'assignment', link: '/registrations' },

  Scans: { name: 'Scans', icon: 'phonelink_ring', link: '/scans' },
  ScanDetail: { name: 'Scan', icon: 'phonelink_ring', link: '/scan/:scanId' },
  ScanEdit: { name: 'Edit scan', icon: 'phonelink_ring', link: '/scan/:scanId/edit' },

  Products: {
    name: 'Productgroups',
    icon: 'car-battery',
    fa: true,
    link: '/productGroups',
  },
  ProductDetail: {
    name: 'Product',
    link: '/productGroup/:productGroupId',
  },
  ProductEdit: {
    name: 'Edit product',
    link: '/productGroup/:productGroupId/edit',
  },

  ProductQuestions: {
    name: 'Productquestions',
    icon: 'file-signature',
    fa: true,
    link: '/claimProductQuestions',
  },
  ProductQuestionDetail: {
    name: 'Productquestion',
    link: '/claimProductQuestion/:claimProductQuestionId',
  },
  ProductQuestionEdit: {
    name: 'Edit productquestion',
    link: '/claimProductQuestion/:claimProductQuestionId/edit',
  },

  Users: { name: 'Users', icon: 'people', link: '/users' },
  UserDetail: { name: 'User', link: '/user/:userId' },
  UserEdit: { name: 'Edit user', link: '/user/:userId/edit' },

  Reports: { name: 'Reports', icon: 'assessment', link: '/reports' },

  TranslationsAll: {
    name: 'Translations',
    icon: 'globe-americas',
    fa: true,
    link: '/translations',
  },
  Translations: {
    name: 'Translations',
    icon: 'globe-americas',
    fa: true,
    link: '/translation/:entityTable/:entityId/:entityField/list/:currentValue',
  },
  TranslationDetail: {
    name: 'Translation',
    icon: 'globe-americas',
    fa: true,
    link: '/translation/:entityTable/:entityId/:entityField/:translationId',
  },
  TranslationEdit: {
    name: 'Edit translation',
    icon: 'globe-americas',
    fa: true,
    link: '/translation/:entityTable/:entityId/:entityField/:translationId/edit',
  },
};

const AdapterLink = React.forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => (
  <Link innerRef={ref as any} {...props} />
));

export const NavMenu: React.FC<{}> = (props) => {
  const { isNavMenuOpen } = useContext(NavMenuContext);
  const { location } = useReactRouter();
  const { t } = useTranslation();
  const auth = useContext(AuthContext);

  const userRole = auth && auth.user && auth.user.role ? auth.user.role : Roles.customer;

  const mapMenuItem = (item: INavMenuLink) => {
    return (
      <ListItem
        button={true}
        key={item.name}
        component={AdapterLink}
        to={item.link || ''}
        selected={location.pathname === '/' + item.link}
      >
        <ListItemIcon className={styles.listItemIcon}>
          {item.fa ? <Icon className={'fa fa-' + item.icon} /> : <Icon>{item.icon}</Icon>}
        </ListItemIcon>
        <ListItemText primary={t(item.name)} />
      </ListItem>
    );
  };
  return (
    <Drawer
      className={`${styles.drawer} ${isNavMenuOpen ? styles.isOpen : ''}`}
      variant="permanent"
      classes={{
        paper: styles.drawerPaper,
      }}
    >
      <div className={styles.toolbar} />

      {userRole === Roles.customer ? (
        <>
          <List>{[MenuItems.Dashboard].map(mapMenuItem)}</List>
        </>
      ) : [Roles.administrator, Roles.superuser].includes(userRole) ? (
        <>
          <List>{[MenuItems.Home].map(mapMenuItem)}</List>
          <Divider className={styles.divider} />
          <List>
            {[MenuItems.Claims, MenuItems.Registrations, MenuItems.Customers].map(mapMenuItem)}
          </List>
          <Divider className={styles.divider} />
          <List>{[MenuItems.Scans].map(mapMenuItem)}</List>
          <Divider className={styles.divider} />
          <List>
            {[
              MenuItems.Products,
              MenuItems.ProductQuestions,
              MenuItems.Users,
              MenuItems.Reports,
            ].map(mapMenuItem)}
          </List>
        </>
      ) : null}
      <div className={`${styles.version}`}>v1.0.4</div>
    </Drawer>
  );
};
