import React, { useContext, useEffect, useState } from 'react';

import useReactRouter from 'use-react-router';

import { CustomFormBase } from '../../components/form/CustomFormBase';
import { MenuItems } from '../../components/layout/NavMenu';
import { ICustomer } from '../../models/ICustomer';
import { api } from '../../services/api';
import { AuthContext } from '../auth/Auth';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '../../components/Snackbar';

export const DashboardRegistrationEdit: React.FC<{}> = (props) => {
  const { match, history } = useReactRouter();
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const auth = useContext(AuthContext);
  const [registration, setRegistration] = useState<any>();

  const getRegistration = async () => {
    const registrationId = (match.params as any).registrationId;

    const result = await api.get(`/dashboard/registration/${registrationId}`);
    if (result) {
      setRegistration(result.data.data);
    }
  };

  const updateRegistration = async (values: any) => {
    try {
      const result = await api.put(`/dashboard/registration/${registration.id}`, {
        changes: values,
      });

      if (result.status !== 200) {
        throw new Error(result.statusText);
      }

      snackbar.success(t('Successfully saved {{text}}', { text: t('Registration') }));
      history.push('/dashboard');
    } catch (err) {
      console.error(err);

      snackbar.error(t('Error occured while saving {{text}}', { text: t('Registration') }));
    }
  };

  useEffect(() => {
    getRegistration();
  }, []);

  if (!registration || !registration.id) {
    return <div>...</div>;
  }
  return (
    <>
      <CustomFormBase
        title={t('Registration')}
        breadcrumbs={[MenuItems.Dashboard]}
        fields={[
          {
            label: 'Date',
            field: 'createdAt',
            col: 12,
            value: registration.createdAt,
            type: 'date',
            readonly: true,
          },
          { label: 'Code', field: 'code', col: 12, value: registration.code, readonly: true },
          {
            label: 'Productgroup',
            field: 'productGroup.name',
            col: 12,
            value: registration.productGroup.name,
            readonly: true,
          },
          { label: 'Type', field: 'type', col: 12, value: registration.type, readonly: true },
          {
            label: 'Order Number',
            field: 'orderNumber',
            col: 12,
            value: registration.orderNumber,
            readonly: true,
          },
          { label: 'Reference 1', field: 'reference1', col: 12, value: registration.reference1 },
          { label: 'Reference 2', field: 'reference2', col: 12, value: registration.reference2 },
        ]}
        onSubmit={updateRegistration}
        onCancel={() => {
          history.push('/dashboard');
        }}
      />
    </>
  );
};
