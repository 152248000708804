import React, { useContext, useEffect, useState } from 'react';

import useReactRouter from 'use-react-router';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';

import { MenuItems } from '../../components/layout/NavMenu';
import { Table } from '../../components/table/Table';
import theme from '../../utils/Theme';
import { api } from '../../services/api';
import { ICustomer } from '../../models/ICustomer';
import { Breadcrumbs } from '../../components/layout/Breadcrumbs';
import { AuthContext } from '../auth/Auth';
import { ModelFormBase } from '../../components/form/ModelFormBase';

export const Dashboard: React.FC<{}> = (props) => {
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const { history } = useReactRouter();
  const [refresh, setRefresh] = useState<number>(0);
  const [customer, setCustomer] = useState<ICustomer>();

  const getCustomer = async () => {
    if (!auth || !auth.user || !auth.user.customerId) {
      return;
    }

    const result = await api.get(`/dashboard/customer`, {
      pageSize: 99,
      include: 'users',
    });
    if (result) {
      setCustomer(result.data);
    }
  };

  useEffect(() => {
    getCustomer();
  }, []);

  if (!customer || !customer.id) {
    return <div>...</div>;
  }

  return (
    <>
      <Breadcrumbs items={[MenuItems.Dashboard]} />
      <ModelFormBase
        url="/dashboard/customer"
        name="customer"
        model={customer}
        fields={[
          { label: 'Address', field: 'address', col: 4 },
          // { label: 'Housenumber', field: 'houseNumber', col: 2 },
          // { label: 'Addition', field: 'houseNumberAddition', col: 4 },
          { label: 'Postal code', field: 'postalCode', col: 2 },
          { label: 'City', field: 'city', col: 3 },
          { label: 'Country', field: 'country', col: 3 },
          { label: 'Contact person', field: 'contact', col: 4 },
          { label: 'Phone', field: 'phone', col: 2 },
          { label: 'Email', field: 'email', col: 3 },
          { label: 'Customer ID', field: 'wetacCustomerId', col: 3 },
        ]}
        disableDelete={true}
        disableEditing={true}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            history.push('/dashboard/update');
          }}
        >
          {t('dashboard.submit_changes')}
        </Button>
      </ModelFormBase>
      <Table
        url={`/dashboard/registration`}
        include={'productGroup'}
        defaultSortColumn={'productSetNr'}
        defaultSortOrder={'desc'}
        headers={[]}
        title={'Registrations'}
        showSelect={true}
        disableAdd={true}
        disableDelete={true}
        columns={[
          { id: 'code', label: 'Product code' }, // { id: 'productSetNr', label: 'Product set' },
          { id: 'productGroup.name', label: 'Product group' },
          //{ id: 'type', label: 'Type' },
          // { id: 'orderNumber', label: 'Order Number' },
          // { id: 'productionDate', label: 'Production Date', type: 'date' },
          //{ id: 'sequenceNumber', label: 'Sequence Number' },
          { id: 'createdAt', label: 'Created At', type: 'date' },
        ]}
      />
      <Table
        url={`/dashboard/claim`}
        headers={[]}
        include="claimStatus,customer"
        title={'Claims'}
        showSelect={true}
        disableAdd={true}
        disableDelete={true}
        columns={[
          {
            id: 'claimStatus.name',
            label: 'Status',
            colorField: 'claimStatus.code',
            colors: [
              { value: 'in_progress', color: theme.colors.warning.light },
              { value: 'rejected', color: theme.colors.error.light },
              { value: 'processed', color: theme.colors.success.light },
            ],
            filterField: 'claimStatus.code',
            filters: [
              { value: 'new', name: 'Nieuw' },
              { value: 'in_progress', name: 'In behandeling' },
              { value: 'rejected', name: 'Afgekeurd' },
              { value: 'processed', name: 'Afgehandeld' },
            ],
          },
          { id: 'customer.name', label: 'Customer' },
          { id: 'requesterName', label: 'Requester' },
          { id: 'jobTitle', label: 'Job title' },
          { id: 'companyName', label: 'Company' },
          { id: 'companyLocation', label: 'Location' },
          { id: 'createdAt', label: 'Created At', type: 'date' },
        ]}
      />
      <Table
        url={`/dashboard/scan`}
        //url={'/scan'}
        headers={[]}
        include="customer"
        title={'Scans'}
        disableAdd={true}
        disableDelete={true}
        showSelect={true}
        columns={[
          { id: 'customer.wetacCustomerId', label: 'Customer ID', hide: true },
          { id: 'customer.name', label: 'Customer', hide: true },
          {
            id: 'type',
            label: 'Type',
            mapping: { 1: t('Incoming'), 2: t('Outgoing') },
            // filterField: 'scans.type',
            // filters: [{ value: '1', name: t('Incoming') }, { value: '2', name: t('Outgoing') }],
          },
          { id: 'code', label: 'Code' },
          { id: 'createdAt', label: 'Created At', type: 'date' },
        ]}
        defaultSortColumn="createdAt"
        defaultSortOrder="desc"
        refreshIncrement={refresh}
        onClick={(scan: any) => {
          //history.push(`/scan/${scan.id}`);
        }}
      />
    </>
  );
};
