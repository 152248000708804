import React, { useContext, useEffect, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Icon,
  ListItemText,
  Paper,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useReactRouter from 'use-react-router';

import { api } from '../../services/api';
import { SettingsContext } from '../../services/settings';
import { toDutchString } from '../../utils/Extensions';
import { AppDateTime } from '../Date';
import { Breadcrumbs } from '../layout/Breadcrumbs';
import { INavMenuLink } from '../layout/NavMenu';
import { useSnackbar } from '../Snackbar';

import styles from './ViewFormBase.module.scss';

const Form: React.FC<{}> = (props) => {
  return (
    <Grid container={true} spacing={1} className={styles.form}>
      {props.children}
    </Grid>
  );
};

const FormLabel: React.FC<{ label: string; value: any; col?: any }> = (props) => {
  const { t } = useTranslation();
  return (
    <Grid item={true} sm={props.col || 12}>
      <ListItemText className={styles.label}>{t(props.label)}</ListItemText>
      <ListItemText className={styles.value}>
        {props.value}
        {props.children}
      </ListItemText>
    </Grid>
  );
};

export interface IFormLabel {
  label: string;
  field: string;
  col?: number;
  type?: string;
  translatable?: boolean;
  mapping?: any;
  component?: (entity: any) => any;
}

export const ViewFormBase: React.FC<{
  url: string;
  name: string;
  idParam: string;
  fields: IFormLabel[];
  breadcrumbs?: INavMenuLink[];
  include?: string;
  disableEditing?: boolean;
  disableDelete?: boolean;
}> = (props) => {
  const { match, history } = useReactRouter();
  const { t, i18n } = useTranslation();
  const snackbar = useSnackbar();
  const settings = useContext(SettingsContext);
  const [entity, setEntity] = useState<any>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

  const onEditClick = () => {
    history.push(match.url + '/edit');
  };

  const onDeleteClick = () => {
    setOpenDeleteDialog(true);
  };

  const deleteEntity = async () => {
    const result = await api.delete(props.url + '/' + (match.params as any)[props.idParam]);
    if (result && result.data === 'OK') {
      snackbar.success(t('form.delete_success', { text: t(entity.name || props.name) }));
      history.push(props.url + 's');
    } else {
      snackbar.error(t('form.delete_fail', { text: t(entity.name || props.name) }));
    }
  };

  const getValue = (field: IFormLabel) => {
    let value = entity;
    if (!value) {
      return '';
    }

    if (field.component) {
      return field.component(value);
    }
    if (field.field.indexOf('.') > -1) {
      for (const key of field.field.split('.')) {
        value = value[key];
        if (!value) {
          return '';
        }
      }
    } else {
      value = value[field.field];
    }

    if (!value && field.type !== 'boolean') {
      return '';
    }

    switch (field.type) {
      case 'date':
        value = toDutchString(new Date(value));
        break;
      case 'boolean':
        value = value ? t('Yes') : t('No');
    }

    if (field.mapping) {
      value = field.mapping[value];
    }

    return value;
  };

  useEffect(
    () => {
      const getEntity = async () => {
        const result = await api.get(props.url + '/' + (match.params as any)[props.idParam], {
          include: props.include || '',
        });
        if (result && result.data.data) {
          setEntity(result.data.data);
        } else {
          history.push(props.url + 's');
        }
      };
      getEntity();
    },
    [i18n.language],
  );

  if (!entity) {
    return null;
  }

  return (
    <>
      <Dialog
        open={openDeleteDialog}
        onClose={() => {
          setOpenDeleteDialog(false);
        }}
      >
        <DialogTitle>
          {t('form.delete_confirmation_title', { text: t(entity.name || props.name) })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('form.delete_confirmation_text', { text: t(entity.name || props.name) })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDeleteDialog(false);
            }}
            color="primary"
          >
            {t('Cancel')}
          </Button>
          <Button
            onClick={() => {
              setOpenDeleteDialog(false);
              deleteEntity();
            }}
            color="primary"
            autoFocus={true}
          >
            {t('OK')}
          </Button>
        </DialogActions>
      </Dialog>
      <Breadcrumbs items={[...(props.breadcrumbs || []), { name: t(entity.name || props.name) }]} />
      <Paper className={styles.paper}>
        <div className={styles.header}>
          <Typography variant="h6" className={styles.title}>
            {t(entity.name) || t(props.name)}
          </Typography>
        </div>
        <div className={styles.content}>
          <Form>
            {props.fields.map((field, index) => {
              return (
                <FormLabel
                  key={index}
                  label={field.label}
                  value={getValue(field)}
                  col={field.col || 12}
                >
                  {field.translatable && (
                    <Link
                      onClick={() => {
                        settings.updateSettings({
                          translationReturnUrl: history.location.pathname,
                          translationReturnName: t(entity.name) || t(props.name),
                        });
                      }}
                      to={`/translation/${props.url.replace('/', '')}s/${
                        (match.params as any)[props.idParam]
                      }/${field.field}/list/${getValue(field)}`}
                      className={styles.translationLink}
                    >
                      <i className="fa fa-globe-americas" />
                    </Link>
                  )}
                </FormLabel>
              );
            })}
          </Form>
        </div>
        <div className={styles.footer}>
          <div>
            {!props.disableEditing && (
              <Button variant="contained" color="primary" onClick={onEditClick}>
                <Icon>edit</Icon>
              </Button>
            )}
            {!props.disableDelete && (
              <Button variant="contained" color="default" onClick={onDeleteClick}>
                <Icon>delete</Icon>
              </Button>
            )}
            {props.children}
          </div>
          <Typography variant="caption" id="tableTitle" className={styles.created}>
            {t('Created')}: <AppDateTime value={entity.createdAt} />
            {entity.updatedAt > entity.createdAt ? (
              <>
                , {t('Last modified')}: <AppDateTime value={entity.updatedAt} />
              </>
            ) : null}
          </Typography>
        </div>
      </Paper>
    </>
  );
};
