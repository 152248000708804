import React from 'react';

import useReactRouter from 'use-react-router';

import { Table } from '../../components/table/Table';

export const Registrations: React.FC<{}> = (props) => {
  const { history } = useReactRouter();
  return (
    <>
      <Table
        url={'/registration'}
        headers={[]}
        include={'productGroup,customer'}
        title={'Registrations'}
        disableAdd={true}
        showSelect={true}
        columns={[
          { id: 'customer.wetacCustomerId', label: 'ID', hide: true },
          { id: 'customer.name', label: 'Customer' },
          { id: 'code', label: 'Product code' },
          // { id: 'productSetNr', label: 'Product set' },
          { id: 'productGroup.name', label: 'Product group' },
          { id: 'type', label: 'Type' },
          { id: 'orderNumber', label: 'Order Number' },
          { id: 'productionDate', label: 'Production Date', type: 'date' },
          { id: 'sequenceNumber', label: 'Sequence Number' },
          { id: 'createdAt', label: 'Created At', type: 'date' },
        ]}
        onClick={(registration: any) => {
          history.push(`/customer/${registration.customerId}/registration/${registration.id}`);
        }}
      />
    </>
  );
};
