import React from 'react';

import useReactRouter from 'use-react-router';

import { EditFormBase } from '../../components/form/EditFormBase';
import { MenuItems } from '../../components/layout/NavMenu';

export const ClaimEdit: React.FC<{}> = (props) => {
  const { match } = useReactRouter();
  return (
    <>
      <EditFormBase
        url="/claim"
        name="Claim"
        link={`/customer/${(match.params as any).customerId}/claim/${
          (match.params as any).claimId
        }`}
        idParam="claimId"
        breadcrumbs={[MenuItems.CustomerDetail, MenuItems.ClaimDetail]}
        fields={[
          { field: 'requesterName', label: 'Requester', col: 6 },
          { field: 'emailAddress', label: 'E-mail', col: 6 },
          { field: 'jobTitle', label: 'Job title', col: 6 },
          { field: 'companyName', label: 'Company', col: 6 },
          { field: 'companyLocation', label: 'Location', col: 6 },
          { field: 'country', label: 'Country', col: 6 },
          { field: 'remarks', label: 'Remarks', col: 6, type: 'multiline' },
          {
            label: 'Status',
            field: 'claimStatusId',
            required: true,
            col: 6,
            type: 'select',
            optionsUrl: '/claim/statuses',
            optionsValueField: 'id',
            optionsLabelField: 'name',
          },
        ]}
      />
    </>
  );
};
