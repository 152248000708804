import React, { useEffect, useState } from 'react';

import { Button, Grid, ListItemText, Paper, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useReactRouter from 'use-react-router';
import validator from 'validator';

import { FormComponent, IFormInput, IValidationState } from '../input/FormComponent';
import { Breadcrumbs } from '../layout/Breadcrumbs';
import { INavMenuLink } from '../layout/NavMenu';
import { useSnackbar } from '../Snackbar';

import styles from './EditFormBase.module.scss';
import { toDutchString } from '../../utils/Extensions';

const Form: React.FC<{}> = (props) => {
  return (
    <Grid container={true} spacing={1} className={styles.form}>
      {props.children}
    </Grid>
  );
};

const FormLabel: React.FC<{ label: string; value: any; col?: any }> = (props) => {
  const { t } = useTranslation();
  return (
    <Grid item={true} sm={props.col || 12}>
      <ListItemText className={styles.label}>{t(props.label)}</ListItemText>
      <ListItemText className={styles.value}>
        {props.value}
        {props.children}
      </ListItemText>
    </Grid>
  );
};

export const CustomFormBase: React.FC<{
  title: string;
  fields: IFormInput[];
  breadcrumbs?: INavMenuLink[];
  onSubmit: (values: { [field: string]: any }) => void;
  onCancel: () => void;
  submitButtonLabel?: string;
  cancelButtonLabel?: string;
}> = (props) => {
  const { history } = useReactRouter();
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const [fieldValidations, setFieldValidations] = useState<IValidationState[]>([]);

  const [values, setValues] = useState<{ [field: string]: any }>({});

  let returnUrl: string;
  for (const queryParam of window.location.search.substring(1).split('&')) {
    if (queryParam.split('=')[0] === 'return') {
      returnUrl = queryParam.split('=')[1];
    }
  }

  useEffect(() => {
    const initValues: { [field: string]: any } = {};
    for (const field of props.fields) {
      initValues[field.field] = field.value;
    }

    setValues(initValues);

    // Init object with hidden value
    const objHiddenValues: any = {};
    for (const field of props.fields) {
      if (field.type === 'hidden') {
        objHiddenValues[field.field] = field.value;
      }
    }
  }, []);

  const onSaveClick = async () => {
    // Validate all inputs
    if (
      props.fields.filter((field, index) => !validateInput(field, values[field.field], index))
        .length
    ) {
      snackbar.warning(t('validation.check_input'));
      return;
    }

    props.onSubmit(values);
  };

  const onCancelClick = () => {
    if (returnUrl) {
      history.push(returnUrl);
      return;
    }

    props.onCancel();
  };

  const validateInput = (field: IFormInput, value: any, index: number): boolean => {
    // Validate input
    const newValidationState = { valid: true, dirty: true, message: '' };
    if (field.required && !value) {
      newValidationState.valid = false;
      newValidationState.message = t('validation.required');
    }
    if (field.type === 'email' && value && !validator.isEmail(value)) {
      newValidationState.valid = false;
      newValidationState.message = t('validation.invalid_email');
    }

    fieldValidations[index] = newValidationState;
    setFieldValidations(fieldValidations);

    return newValidationState.valid;
  };

  const getValue = (field: IFormInput) => {
    let value = field.value;
    if (!value) {
      return '';
    }

    switch (field.type) {
      case 'date':
        value = toDutchString(new Date(value));
        break;
      case 'boolean':
        value = value ? t('Yes') : t('No');
    }

    // if (field.mapping) {
    //   value = field.mapping[value];
    // }

    return value;
  };

  return (
    <>
      <Breadcrumbs items={[...(props.breadcrumbs || []), { name: props.title }]} />
      <Paper className={styles.paper}>
        <div className={styles.header}>
          <Typography variant="h6" className={styles.title}>
            {props.title}
          </Typography>
        </div>
        <div className={styles.content}>
          <Form>
            {props.fields.map((field, index) => {
              return field.readonly ? (
                <FormLabel
                  key={index}
                  label={field.label ? field.label : ''}
                  value={getValue(field)}
                  col={field.col || 12}
                />
              ) : (
                <FormComponent
                  key={index}
                  field={field}
                  value={values[field.field]}
                  validation={fieldValidations[index] || { valid: true, dirty: false, message: '' }}
                  onChange={(value) => {
                    setValues({ ...values, [field.field]: value });
                    validateInput(field, value, index);
                  }}
                />
              );
            })}
          </Form>
        </div>
        <div className={styles.footer}>
          <div>
            <Button variant="contained" color="primary" onClick={onSaveClick}>
              {props.submitButtonLabel || t('Save')}
            </Button>
            <Button variant="contained" color="default" onClick={onCancelClick}>
              {props.cancelButtonLabel || t('Cancel')}
            </Button>
          </div>
        </div>
      </Paper>
    </>
  );
};
