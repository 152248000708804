import React, { useContext, useEffect } from 'react';

import { CssBaseline } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { AuthContext } from '../../features/auth/Auth';
import { Login } from '../../features/auth/Login';
import { SettingsContext } from '../../services/settings';
import { Header } from './Header';
import { Main } from './Main';
import { NavMenu } from './NavMenu';

export const Root: React.FC = (props) => {
  const settings = useContext(SettingsContext);
  const { t, i18n } = useTranslation();
  const auth = useContext(AuthContext);

  useEffect(() => {
    i18n.changeLanguage(settings.languageCode);
  }, []);

  return (
    <div className="app">
      <CssBaseline />

      {auth.isAuthenticated ? (
        <>
          <Header />
          <NavMenu />
          <Main />
        </>
      ) : (
        <Login />
      )}
    </div>
  );
};

export default Root;
