import React from 'react';

import { Breadcrumbs } from '../../components/layout/Breadcrumbs';
import { MenuItems } from '../../components/layout/NavMenu';
import { Table } from '../../components/table/Table';

export const Customers: React.FC<{}> = (props) => {
  return (
    <>
      <Breadcrumbs items={[MenuItems.Customers]} />
      <Table
        url={'/customer'}
        headers={[]}
        title={'Customers'}
        defaultSortColumn={'name'}
        defaultSortOrder={'asc'}
        columns={[
          { id: 'wetacCustomerId', label: 'Customer ID' },
          { id: 'name', label: 'Name' },
          // { id: 'contact', label: 'Contact' },
          // { id: 'email', label: 'Email' },
          // { id: 'address', label: 'Address' },
          // { id: 'postalCode', label: 'Postal code' },
          { id: 'city', label: 'City' },
          { id: 'createdAt', label: 'Created At', type: 'date' },
          { id: 'updatedAt', label: 'Updated At', type: 'date' },
        ]}
      />
    </>
  );
};
