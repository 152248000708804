import React, { useEffect, useState } from 'react';

import { Chip, Icon, InputAdornment, TextField } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import useDebounce from '../../hooks/useDebounce';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  search: {
    width: theme.spacing(35),
  },
  clearSearch: {
    color: theme.palette.text.secondary,
    cursor: 'pointer',
  },
  title: {
    flex: '0 0 auto',
    marginRight: theme.spacing(1),
  },
  filterActive: {
    color: theme.palette.grey[800],
    backgroundColor: theme.palette.grey[300],
    margin: theme.spacing(0.5),
    '&:focus,&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
  filterInactive: {
    color: theme.palette.grey[500],
    backgroundColor: theme.palette.grey[100],
    margin: theme.spacing(0.5),
    '&:focus,&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
}));

export const TableToolbar: React.FC<{
  numSelected: number;
  selectedIds: number[];
  title: string;
  onSearchChanged: (text: string) => void;
  searchText: string;
  filters: Array<{ value: string; name: string; active: boolean }>;
  onFilterChanged: (filter: { value: string; name: string; active: boolean }) => void;
  onDelete: () => void;
  selectActions?: (ids: number[]) => JSX.Element;
  hideDelete?: boolean;
}> = (props) => {
  const { t } = useTranslation();
  const classes = useToolbarStyles({});
  const { numSelected } = props;

  const [searchText, setSearchText] = useState<string>('');
  const debouncedSearchText = useDebounce<string>(searchText, 500);

  useEffect(
    () => {
      props.onSearchChanged(debouncedSearchText);
    },
    [debouncedSearchText],
  );

  return (
    <Toolbar
      className={clsx((classes as any).root, {
        [(classes as any).highlight]: numSelected > 0,
      })}
    >
      <div className={(classes as any).title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} {t('selected')}
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            {props.title}
          </Typography>
        )}
      </div>

      <div className={(classes as any).actions}>
        {!props.hideDelete && numSelected > 0 ? (
          <>
            <Tooltip title={t('Delete')}>
              <IconButton
                aria-label={t('Delete')}
                onClick={() => (props.onDelete ? props.onDelete() : null)}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>

            {props.selectActions ? props.selectActions(props.selectedIds) : null}
          </>
        ) : null}
      </div>

      {numSelected < 1 && (
        <>
          {props.filters &&
            props.filters.map((filter, ix) => (
              <Chip
                key={ix}
                clickable={true}
                onClick={() => props.onFilterChanged(filter)}
                label={t(filter.name)}
                className={
                  filter.active ? (classes as any).filterActive : (classes as any).filterInactive
                }
              />
            ))}
          <div className={(classes as any).spacer} />
          <div>
            <TextField
              placeholder={t('Search')}
              className={(classes as any).search}
              onChange={(e) => setSearchText(e.target.value)}
              value={searchText}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon>search</Icon>
                  </InputAdornment>
                ),
                endAdornment:
                  searchText.length > 0 ? (
                    <InputAdornment position="end" className={(classes as any).clearSearch}>
                      <Icon title="Clear" onClick={() => setSearchText('')}>
                        close
                      </Icon>
                    </InputAdornment>
                  ) : null,
              }}
            />
          </div>
        </>
      )}
    </Toolbar>
  );
};
