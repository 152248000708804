import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { api } from '../../services/api';
import { IFormInput, IValidationState } from './FormComponent';

import styles from './FormSelect.module.scss';

export const FormSelect: React.FC<{
  field: IFormInput;
  value: any;
  validation: IValidationState;
  onChange?: (value: any) => void;
}> = (props) => {
  const { t } = useTranslation();
  const [urlOptions, setUrlOptions] = useState<Array<{ label: string; value: string }>>([]);

  useEffect(
    () => {
      const getOptions = async () => {
        if (!props.field.optionsUrl) {
          return;
        }
        const options = await api.get(props.field.optionsUrl, {
          pageSize: 9999,
          sortBy: props.field.optionsLabelField,
          sortOrder: 'asc',
        });

        if (options && options.data.data) {
          setUrlOptions(
            options.data.data.map((o: any) => ({
              label: o[props.field.optionsLabelField || 'label'],
              value: o[props.field.optionsValueField || 'value'],
            })),
          );
        }
      };
      getOptions();
    },
    [props.field.optionsUrl],
  );

  return (
    <FormControl className={styles.formControl} error={!props.validation.valid}>
      <InputLabel shrink={true}>
        {t(props.field.label || '') + (props.field.required ? ' *' : '')}
      </InputLabel>
      <Select
        value={props.field.type === 'boolean' ? !!props.value : props.value || ''}
        className={styles.input}
        placeholder={props.field.label}
        onChange={(event) => {
          if (props.onChange) {
            props.onChange(event.target.value);
          }
        }}
      >
        {props.field.options &&
          props.field.options.map((option, optionIndex) => (
            <MenuItem key={optionIndex} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        {urlOptions &&
          urlOptions.map((option, optionIndex) => (
            <MenuItem key={optionIndex} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
      </Select>
      {!props.validation.valid &&
        props.validation.message && (
          <FormHelperText id="component-error-text">{props.validation.message}</FormHelperText>
        )}
    </FormControl>
  );
};
