import React, { useContext, useEffect, useState } from 'react';

import useReactRouter from 'use-react-router';

import { CustomFormBase } from '../../components/form/CustomFormBase';
import { MenuItems } from '../../components/layout/NavMenu';
import { ICustomer } from '../../models/ICustomer';
import { api } from '../../services/api';
import { AuthContext } from '../auth/Auth';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '../../components/Snackbar';

export const UpdateInfo: React.FC<{}> = (props) => {
  const { history } = useReactRouter();
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const auth = useContext(AuthContext);
  const [customer, setCustomer] = useState<ICustomer>();

  const getCustomer = async () => {
    if (!auth || !auth.user || !auth.user.customerId) {
      return;
    }

    const result = await api.get(`/dashboard/customer`, {
      pageSize: 99,
      include: 'users',
    });
    if (result) {
      setCustomer(result.data);
    }
  };

  const updateInfo = async (values: any) => {
    try {
      await api.post('/dashboard/info', { changes: values });
      snackbar.success(t('dashboard.submit_changes_success'));
      history.push('/dashboard');
    } catch (err) {
      console.error(err);
      snackbar.warning(t('unknown_error_occurred'));
    }
  };

  useEffect(() => {
    getCustomer();
  }, []);

  if (!customer || !customer.id) {
    return <div>...</div>;
  }
  return (
    <>
      <CustomFormBase
        title={t('dashboard.submit_changes')}
        breadcrumbs={[MenuItems.Dashboard]}
        fields={[
          { label: 'Contact person', field: 'contact', col: 12, value: customer.contact },
          { label: 'Email', field: 'email', type: 'email', col: 12, value: customer.email },
          { label: 'Phone', field: 'phone', col: 12, value: customer.phone },
        ]}
        onSubmit={updateInfo}
        onCancel={() => {
          history.push('/dashboard');
        }}
        submitButtonLabel={t('dashboard.submit_changes')}
      />
    </>
  );
};
