import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      // Translations
      translations: {
        title: 'Translations for: {{name}}',
        nl: 'Dutch',
        en: 'English',
        fr: 'French',
        de: 'German',
        back: 'Back to {{name}}',
      },
      password_reset_send: 'Your new password has been sent by email',
      scans: {
        're-assign': {
          title: 'Assign scans',
          message: 'Assign selected scans to customer:',
        },
      },
    },
  },
  nl: {
    translation: {
      // Common
      Name: 'Naam',
      Add: 'Toevoegen',
      Edit: 'Wijzig',
      Delete: 'Verwijderen',
      Save: 'Opslaan',
      Cancel: 'Annuleren',
      Search: 'Zoeken',
      Created: 'Aangemaakt',
      'Last modified': 'Bijgewerkt',
      'Created At': 'Aangemaakt op',
      'Updated At': 'Bijgewerkt op',
      Type: 'Type',
      Required: 'Verplicht',
      Remarks: 'Opmerkingen',
      Text: 'Tekst',
      Multiline: 'Tekstvak',
      Number: 'Nummer',
      Voltage: 'Voltage',
      Date: 'Datum',
      Year: 'Jaar',
      'Yes/No': 'Ja/Nee',
      Yes: 'Ja',
      No: 'Nee',
      Select: 'Selecteer',
      selected: 'geselecteerd',
      'Select all': 'Selecteer alles',
      Value: 'Waarde',
      Language: 'Taal',
      Back: 'Terug',
      Profile: 'Profiel',
      Logout: 'Uitloggen',

      // Login
      Login: 'Inloggen',
      'Forgot password?': 'Wachtwoord vergeten?',
      'is created by': 'is gemaakt door',
      'Send reset link': 'Stuur reset link',
      password_reset_send: 'Er is een e-mail verstuurd met daarin uw nieuwe wachtwoord',

      // Entities
      user: 'gebruiker',
      User: 'Gebruiker',
      Users: 'Gebruikers',
      customer: 'klant',
      Customer: 'Klant',
      Customers: 'Klanten',
      registration: 'registratie',
      Registration: 'Registratie',
      Registrations: 'Registraties',
      claim: 'claim',
      Claim: 'Claim',
      Claims: 'Claims',
      product: 'product',
      Product: 'Product',
      Products: 'Producten',
      report: 'rapport',
      Report: 'Rapport',
      Reports: 'Rapporten',
      'Product set': 'Productset',
      Productgroup: 'Productgroep',
      Productgroups: 'Productgroepen',
      productGroup: 'Productgroep',
      productquestion: 'Productvraag',
      Productquestion: 'Productvraag',
      Productquestions: 'Productvragen',
      productQuestion: 'Productvraag',
      translation: 'vertaling',
      Translation: 'Vertaling',
      Translations: 'Vertalingen',

      // Contact and location info
      Address: 'Adres',
      'Postal code': 'Postcode',
      City: 'Plaats',
      Country: 'Land',
      'Contact person': 'Contactpersoon',
      Email: 'E-mail',
      'Email address': 'E-mailadres',
      Phone: 'Telefoon',
      Location: 'Locatie',

      // Messages
      'Successfully saved {{text}}': '{{text, capitalize}} succesvol opgeslagen',
      'Error occured while saving {{text}}': 'Fout bij het opslaan van {{text}}',
      'No rows found': 'Geen gegevens gevonden',

      // User
      Role: 'Rol',
      Password: 'Wachtwoord',
      'New password': 'Nieuw wachtwoord (optioneel)',
      Administrator: 'Beheerder',
      Superuser: 'Superuser',

      // Customer
      'Customer ID': 'Klant ID',

      // Product
      'Order Number': 'Ordernummer',
      'Production Date': 'Productiedatum',
      'Sequence Number': 'Volgnummer',
      Question: 'Vraag',
      Order: 'Volgorde',
      'Reference 1': 'Referentie 1',
      'Reference 2': 'Referentie 2',

      // Claim
      Requester: 'Aanvrager',
      'Job title': 'Functie',
      Company: 'Bedrijf',

      // Scan
      Incoming: 'Inkomend',
      Outgoing: 'Uitgaand',
      'Incoming without outgoing': 'Inkomend zonder uitgaand',
      scans: {
        're-assign': {
          title: 'Scans toewijzen',
          message: 'De geselecteerde scans toewijzen aan klant:',
        },
      },

      unknown_error_occurred: 'Er is een onbekende fout opgetreden',
      dashboard: {
        Dashboard: 'Dashboard',
        submit_changes: 'Wijzigingen doorgeven',
        submit_changes_success: 'Wijzigingen succesvol doorgegeven',
      },

      // Translations
      translations: {
        title: 'Vertalingen voor: {{name}}',
        nl: 'Nederlands',
        en: 'Engels',
        fr: 'Frans',
        de: 'Duits',
        back: 'Terug naar {{name}}',
      },

      form: {
        delete_confirmation_title: 'Verwijderen',
        delete_confirmation_text: "Weet u zeker dat u '{{text}}' wilt verwijderen?",
        delete_success: "'{{text, capitalize}}' succesvol verwijderd",
        delete_fail: "Fout bij het verwijderen van '{{text}}'",
      },

      validation: {
        required: 'Verplicht',
        invalid_email: 'Ongeldig e-mailadres',
        check_input: 'Controleer uw invoer',
        not_unique: 'Deze waarde is al in gebruik',
        not_empty: 'Waarde mag niet leeg zijn',
      },
    },
  },
  de: {
    translation: {
      // Common
      Name: 'Name',
      Add: 'Hinzufügen',
      Edit: 'Bearbeiten',
      Delete: 'Löschen',
      Save: 'sparen',
      Cancel: 'Stornieren',
      Search: 'Suche',
      Created: 'Erstellt',
      'Last modified': 'Zuletzt bearbeitet',
      'Created At': 'Hergestellt in',
      'Updated At': 'Aktualisiert am',
      Type: 'Typ',
      Required: 'Verplicht',
      Remarks: 'Opmerkingen',
      Text: 'Tekst',
      Multiline: 'Tekstvak',
      Number: 'Nummer',
      Voltage: 'Voltage',
      Date: 'Datum',
      Year: 'Jaar',
      'Yes/No': 'Ja/Nein',
      Yes: 'Ja',
      No: 'Nein',
      Select: 'Wählen',
      selected: 'ausgewählt',
      'Select all': 'Wählen Sie Alle',

      // Login
      Login: 'Anmeldung',
      'Forgot password?': 'Passwort vergessen?',
      'is created by': 'wird erstellt von',
      'Send reset link': 'Link zum Zurücksetzen senden',

      // Entities
      user: 'Benutzer',
      User: 'Benutzer',
      Users: 'Benutzer',
      customer: 'Kunde',
      Customer: 'Kunde',
      Customers: 'Kunden',
      registration: 'Anmeldung',
      Registration: 'Anmeldung',
      Registrations: 'Anmeldungen',
      product: 'Produkt',
      Product: 'Produkt',
      Products: 'Produkte',
      report: 'Bericht',
      Report: 'Bericht',
      Reports: 'Berichte',

      // Contact and location info
      Address: 'Adresse',
      'Postal code': 'Postleitzahl',
      City: 'Stadt',
      Country: 'Land',
      'Contact person': 'Gesprächspartner',
      Email: 'Email',
      'Email address': 'E-Mail-Addresse',
      Phone: 'Telefon',

      // Messages
      'Successfully saved {{text}}': '{{Text}} erfolgreich gespeichert',
      'Error occured while saving {{text}': 'Fehler beim Speichern von {{text}',
      'No rows found': 'Keine Zeilen gefunden',

      // User
      Role: 'Rolle',
      Password: 'Passwort',
      'New password': 'Neues Passwort (optional)',

      // Customer
      'Customer ID': 'Kundennummer',

      // Product
      'Product set': 'Produktset',
      Productgroup: 'Produktgruppe',
      Productgroups: 'Produktgruppen',
      productGroup: 'Produktgruppe',
      productquestion: 'Produktfrage',
      Productquestion: 'Produktfrage',
      Productquestions: 'Produktfragen',
      productQuestion: 'Produktfrage',
      'Order Number': 'Bestellnummer',
      'Production Date': 'Produktionsdatum',
      'Sequence Number': 'Sequenznummer',
      Question: 'Frage',
      Order: 'Sortierung',

      // Claim
      Requester: 'Anforderer',
      'Job title': 'Berufsbezeichnung',
      Company: 'Firma',

      // Scan
      Incoming: 'Eingehend',
      Outgoing: 'Ausgehend',

      // Translations
      translations: {
        title: 'Traductions pour: {{name}}',
        nl: 'Néerlandais',
        en: 'Anglais',
        fr: 'Français',
        de: 'Allemand',
        back: 'Retour au {{name}}',
      },
    },
  },
  fr: {
    translation: {
      // Common
      Name: 'Prénom',
      Add: 'Ajouter',
      Edit: 'Modifier',
      Delete: 'Supprimer',
      Save: 'Sauvegarder',
      Cancel: 'Annuler',
      Search: 'Chercher',
      Created: 'Créé',
      'Last modified': 'Dernière modification',
      'Created At': 'Créé à',
      'Updated At': 'Mis à jour à',
      Type: 'Type',
      Required: 'Verplicht',
      Remarks: 'Opmerkingen',
      Text: 'Tekst',
      Multiline: 'Tekstvak',
      Number: 'Nummer',
      Voltage: 'Voltage',
      Year: 'Jaar',
      Date: 'Datum',
      'Yes/No': 'Oui/Non',
      Yes: 'Oui',
      No: 'Non',
      Select: 'Sélectionner',
      selected: 'sélectionné',
      'Select all': 'Tout sélectionner',

      // Login
      Login: "S'identifier",
      'Forgot password?': 'Mot de passe oublié?',
      'is created by': 'est créé par',
      'Send reset link': 'Envoyer le lien de réinitialisation',

      // Entities
      user: 'utilisateur',
      User: 'Utilisateur',
      Users: 'Utilisateurs',
      customer: 'client',
      Customer: 'Client',
      Customers: 'Les clients',
      registration: 'enregistrement',
      Registration: 'Enregistrement',
      Registrations: 'Enregistrements',
      product: 'produit',
      Product: 'Produit',
      Products: 'Produits',
      report: 'rapport',
      Report: 'Rapport',
      Reports: 'Rapports',

      // Contact and location info
      Address: 'Adresse',
      'Postal code': 'Code postal',
      City: 'Ville',
      Country: 'Pays',
      'Contact person': 'Contact',
      Email: 'Email',
      'Email address': 'Email',

      Phone: 'Téléphone',
      // Messages
      'Successfully saved {{text}}': 'Enregistré avec succès {{text}}',
      'Error occured while saving {{text}':
        "Une erreur est survenue lors de l'enregistrement de {{text}",
      'No rows found': 'Aucune rangée trouvée',

      // User
      Role: 'Rôle',
      Password: 'Mot de passe',
      'New password': 'Nouveau mot de passe (optionnel)',

      // Customer
      'Customer ID': 'Client ID',

      // Product
      'Product set': 'Ensemble de produits',
      Productgroup: 'Groupe de produit',
      Productgroups: 'Groupe de produits',
      productGroup: 'Groupe de produit',
      productquestion: 'Question de produit',
      Productquestion: 'Question de produit',
      Productquestions: 'Questions de produit',
      productQuestion: 'Question de produit',
      'Order Number': 'Numéro de commande',
      'Production Date': 'Date de production',
      'Sequence Number': 'Numéro de séqunce',
      Question: 'Question',
      Order: 'Ordre',

      // Claim
      Requester: 'Demandeur',
      'Job title': 'Profession',
      Company: 'Entreprise',

      // Scan
      Incoming: 'Entrant',
      Outgoing: 'Sortant',

      // Translations
      translations: {
        title: 'Übersetzungen für: {{name}}',
        nl: 'Niederländisch',
        en: 'Englisch',
        fr: 'Französisch',
        de: 'Deutsch',
        back: 'zuruck zum {{name}}',
      },
    },
  },
};

i18n.languages = ['en', 'nl', 'de', 'fr'];

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: 'en',
  debug: false,
  nsSeparator: false,
  react: {
    useSuspense: false,
  },
  interpolation: {
    escapeValue: false,
    format: (value, format, lng) => {
      switch (format) {
        case 'capitalize':
          return value.charAt(0).toUpperCase() + value.slice(1);
      }
      return value;
    },
  },
});

export default i18n;
