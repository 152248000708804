import React, { useContext } from 'react';

import { Container, Grid } from '@material-ui/core';
import { Redirect, Route } from 'react-router-dom';

import { ClaimDetail } from '../../features/claims/ClaimDetail';
import { ClaimEdit } from '../../features/claims/ClaimEdit';
import { ClaimProductDetail } from '../../features/claims/ClaimProductDetail';
import { Claims } from '../../features/claims/Claims';
import { CustomerDetail } from '../../features/customers/CustomerDetail';
import { CustomerEdit } from '../../features/customers/CustomerEdit';
import { Customers } from '../../features/customers/Customers';
import { ProductQuestions } from '../../features/product-questions/ProductQuestions';
import { ProductQuestionDetail } from '../../features/product-questions/ProductQuestionsDetail';
import { ProductQuestionEdit } from '../../features/product-questions/ProductQuestionsEdit';
import { ProductDetail } from '../../features/products/ProductDetail';
import { ProductEdit } from '../../features/products/ProductEdit';
import { Products } from '../../features/products/Products';
import { RegistrationEdit } from '../../features/registrations/RegistrationEdit';
import { Registrations } from '../../features/registrations/Registrations';
import { Reports } from '../../features/reports/Reports';
import { ScanDetail } from '../../features/scans/ScanDetail';
import { ScanEdit } from '../../features/scans/ScanEdit';
import { Scans } from '../../features/scans/Scans';
import { TranslationDetail } from '../../features/translation/TranslationDetail';
import { TranslationEdit } from '../../features/translation/TranslationEdit';
import { Translations } from '../../features/translation/Translations';
import { UserDetail } from '../../features/users/UserDetail';
import { UserEdit } from '../../features/users/UserEdit';
import { Users } from '../../features/users/Users';
import { Dashboard } from '../../features/dashboard/Dashboard';
import { MenuItems } from './NavMenu';

import styles from './Main.module.scss';
import { Roles } from '../../models/IUser';
import { AuthContext } from '../../features/auth/Auth';
import { UpdateInfo } from '../../features/dashboard/UpdateInfo';
import { DashboardRegistrationEdit } from '../../features/dashboard/RegistrationEdit';
import { DashboardClaimDetail } from '../../features/dashboard/ClaimDetail';

export const Main: React.FC = (props) => {
  const auth = useContext(AuthContext);
  const userRole = auth && auth.user && auth.user.role ? auth.user.role : Roles.customer;

  return (
    <main className={styles.main}>
      <Container maxWidth="xl">
        <Grid container={true} spacing={3}>
          <Grid item={true} xs={12}>
            {userRole === Roles.customer ? (
              <>
                <Route exact={true} path="/" render={() => <Redirect to="dashboard" />} />
                <Route exact={true} path={'/dashboard'} component={Dashboard} />
                <Route exact={true} path={'/dashboard/update'} component={UpdateInfo} />
                <Route
                  exact={true}
                  path={'/dashboard/registration/:registrationId'}
                  component={DashboardRegistrationEdit}
                />
                <Route
                  exact={true}
                  path={'/dashboard/claim/:claimId'}
                  component={DashboardClaimDetail}
                />
              </>
            ) : (
              <>
                {/* <Route exact={true} path="/" component={Home} /> */}
                <Route exact={true} path="/" render={() => <Redirect to="customers" />} />
                <Route
                  exact={true}
                  path={MenuItems.ClaimProductDetail.link}
                  component={ClaimProductDetail}
                />
                <Route exact={true} path={MenuItems.ClaimDetail.link} component={ClaimDetail} />
                <Route exact={true} path={MenuItems.ClaimEdit.link} component={ClaimEdit} />
                <Route exact={true} path={MenuItems.CustomerEdit.link} component={CustomerEdit} />
                <Route
                  exact={true}
                  path={MenuItems.CustomerDetail.link}
                  component={CustomerDetail}
                />
                <Route path={MenuItems.Customers.link} component={Customers} />
                <Route path={MenuItems.Claims.link} component={Claims} />
                <Route path={MenuItems.Registrations.link} component={Registrations} />
                <Route path={MenuItems.RegistrationEdit.link} component={RegistrationEdit} />
                <Route path={MenuItems.Scans.link} component={Scans} />
                <Route exact={true} path={MenuItems.ScanDetail.link} component={ScanDetail} />
                <Route exact={true} path={MenuItems.ScanEdit.link} component={ScanEdit} />
                <Route exact={true} path={MenuItems.ProductEdit.link} component={ProductEdit} />
                <Route exact={true} path={MenuItems.ProductDetail.link} component={ProductDetail} />
                <Route path={MenuItems.Products.link} component={Products} />
                <Route
                  exact={true}
                  path={MenuItems.ProductQuestionEdit.link}
                  component={ProductQuestionEdit}
                />
                <Route
                  exact={true}
                  path={MenuItems.ProductQuestionDetail.link}
                  component={ProductQuestionDetail}
                />
                <Route path={MenuItems.ProductQuestions.link} component={ProductQuestions} />
                <Route exact={true} path={MenuItems.UserEdit.link} component={UserEdit} />
                <Route exact={true} path={MenuItems.UserDetail.link} component={UserDetail} />
                <Route path={MenuItems.Users.link} component={Users} />
                <Route path={MenuItems.Reports.link} component={Reports} />
                <Route exact={true} path={MenuItems.Translations.link} component={Translations} />
                <Route
                  exact={true}
                  path={MenuItems.TranslationEdit.link}
                  component={TranslationEdit}
                />
                <Route
                  exact={true}
                  path={MenuItems.TranslationDetail.link}
                  component={TranslationDetail}
                />
                <Route
                  exact={true}
                  path={MenuItems.TranslationsAll.link}
                  component={Translations}
                />
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </main>
  );
};

export default Main;
