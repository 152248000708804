import React from 'react';

import { Breadcrumbs } from '../../components/layout/Breadcrumbs';
import { MenuItems } from '../../components/layout/NavMenu';
import { Table } from '../../components/table/Table';

export const Products: React.FC<{}> = (props) => {
  return (
    <>
      <Breadcrumbs items={[MenuItems.Products]} />
      <Table
        url={'/productGroup'}
        headers={[]}
        title={'Productgroepen'}
        defaultSortColumn={'name'}
        defaultSortOrder={'asc'}
        columns={[
          { id: 'name', label: 'Name' },
          { id: 'createdAt', label: 'Created At', type: 'date' },
          { id: 'updatedAt', label: 'Updated At', type: 'date' },
        ]}
      />
    </>
  );
};
