import React from 'react';

import Moment from 'react-moment';

export const AppDate: React.FC<{
  value: Date | string;
}> = (props) => {
  return (
    <Moment format={'DD-MM-YYYY'}>
      {props.value instanceof Date ? (props.value as Date).toISOString() : (props.value as string)}
    </Moment>
  );
};

export const AppDateTime: React.FC<{
  value: Date | string;
}> = (props) => {
  return (
    <Moment format={'DD-MM-YYYY HH:mm'}>
      {props.value instanceof Date ? (props.value as Date).toISOString() : (props.value as string)}
    </Moment>
  );
};
