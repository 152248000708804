import React, { useEffect, useRef, useState } from 'react';

import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { default as MuiTable } from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { useTranslation } from 'react-i18next';
import useReactRouter from 'use-react-router';

import download from 'downloadjs';

import { api } from '../../services/api';
import { toDutchString } from '../../utils/Extensions';
import { useSnackbar } from '../Snackbar';
import { TableHead } from './TableHead';
import { TableToolbar } from './TableToolbar';

import {
  Button,
  Checkbox,
  Icon,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import styles from './Table.module.scss';
import { isNumber } from 'util';

export interface ITableColumn {
  id: string;
  label: string;
  type?: 'string' | 'numeric' | 'date' | 'currency' | 'custom';
  component?: (entity: any) => any;
  disablePadding?: boolean;
  colors?: Array<{ value: string; color: string }>;
  colorField?: string;
  filters?: Array<{ value: string; name: string }>;
  filterField?: string;
  mapping?: any;
  hide?: boolean;
}

const useStyles = makeStyles(
  (theme: any) =>
    ({
      root: {
        width: '100%',
        marginTop: theme.spacing(3),
      },
      paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
      },
      table: {
        minWidth: 750,
      },
      tableWrapper: {
        overflowX: 'auto',
      },
      row: {
        cursor: 'pointer',
      },
      cell: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
    } as any),
);

export const Table: React.FC<{
  url: string;
  link?: string;
  headers: any[];
  title: string;
  columns: ITableColumn[];
  defaultSortColumn?: string;
  defaultSortOrder?: 'asc' | 'desc';
  include?: string;
  queryAndFilters?: Array<{ field: string; value: string }>;
  queryOrFilters?: Array<{ field: string; value: string }>;
  disableAdd?: boolean;
  disableDelete?: boolean;
  showSelect?: boolean;
  onClick?: (entity: any) => void;
  onAddClick?: () => void;
  rowsPerPage?: number;
  selectActions?: (ids: number[]) => JSX.Element;
  refreshIncrement?: number;
}> = (props) => {
  const classes = useStyles({});
  const { t, i18n } = useTranslation();
  const [order, setOrder] = useState<'desc' | 'asc' | undefined>(props.defaultSortOrder || 'desc');
  const [orderBy, setOrderBy] = useState(props.defaultSortColumn || 'createdAt');
  const [selected, setSelected] = useState<any>([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(props.rowsPerPage || 10);
  const [rows, setRows] = useState<any>([]);
  const [rowCount, setRowCount] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [filters, setFilters] = useState<
    Array<{ name: string; value: string; active: boolean; field: string }>
  >(
    props.columns
      .filter((c) => c.hide !== true)
      .flatMap(
        (c) =>
          c.filters
            ? c.filters.map((f) => ({ ...f, active: false, field: c.filterField || c.id }))
            : [],
      ),
  );
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const { history } = useReactRouter();
  const snackbar = useSnackbar();

  const [requestCount, setRequestCount] = useState<number>(0);
  const requestCountRef = useRef(-1);

  requestCountRef.current = requestCount;

  const fetchResults = (idOnly?: boolean) => {
    return api.get(props.url, {
      page: idOnly ? 0 : page,
      pageSize: idOnly ? 999999 : rowsPerPage,
      sortOrder: order,
      sortBy: orderBy,
      search: searchText,
      include: props.include || '',
      select: idOnly ? ['id'] : undefined,
      orFilters: filters
        .filter((f) => f.active)
        .map((f) => ({ value: f.value, field: f.field }))
        .concat(props.queryOrFilters || []),
      andFilters: props.queryAndFilters,
    });
  };

  useEffect(
    () => {
      const getData = async () => {
        let fetching = true;
        try {
          if (rows && rows.length) {
            setTimeout(() => {
              if (fetching) {
                setLoading(true);
              }
            }, 400);
          } else {
            setLoading(true);
          }

          const newRequestCount = requestCountRef.current + 1;
          setRequestCount(newRequestCount);

          const result = await fetchResults();

          // Ignore result if newer request has been made
          if (requestCountRef.current > newRequestCount) {
            return;
          }

          fetching = false;

          if (result) {
            setRows(result.data.data);
            setRowCount(result.data.pagination.count);
          }

          setLoading(false);
        } catch {
          fetching = false;
          setLoading(false);
        } finally {
          fetching = false;
          setLoading(false);
        }
      };
      getData();
    },
    [
      order,
      orderBy,
      page,
      rowsPerPage,
      searchText,
      filters,
      i18n.language,
      refreshTrigger,
      props.refreshIncrement,
    ],
  );

  function handleRequestSort(event: any, property: any) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  async function handleSelectAllClick(event: any) {
    if (selected.length >= rowCount) {
      // Deselect all
      setSelected([]);
    } else {
      // Select all
      const allIds = await fetchResults(true);
      setSelected(allIds.data.data.map((r: any) => r.id));
    }
  }

  function handleClick(event: any, row: any) {
    if (props.onClick) {
      props.onClick(row);
    } else {
      history.push((props.link || props.url) + '/' + row.id);
    }
  }

  const handleDelete = () => {
    setOpenDeleteDialog(true);
  };

  const deleteSelected = async () => {
    for (const row of rows.filter((r: any) => selected.indexOf(r.id) >= 0)) {
      await deleteRow(row);
    }
    setSelected([]);
    setRefreshTrigger(refreshTrigger + 1);
  };

  const deleteRow = async (row: any) => {
    const result = await api.delete(props.url + '/' + row.id);
    if (result && result.data === 'OK') {
      snackbar.success(t('form.delete_success', { text: t('item') }));
    } else {
      snackbar.error(t('form.delete_fail', { text: t('item') }));
    }
  };

  function handleCheckboxClick(event: any, row: any) {
    event.stopPropagation();
    if (isSelected(row.id)) {
      setSelected(selected.filter((s: any) => s !== row.id));
    } else {
      setSelected([...selected, row.id]);
    }
  }

  function handleChangePage(event: any, newPage: any) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event: any) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  function handleChangeDense(event: any) {
    setDense(event.target.checked);
  }

  const isSelected = (id: any) => selected.indexOf(id) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rowCount - page * rowsPerPage);

  const getCellValue = (column: ITableColumn, row: any) => {
    if (!row) {
      return '';
    }
    let value = row;
    if (column.id.indexOf('.') > -1) {
      for (const key of column.id.split('.')) {
        value = value[key];
        if (!value) {
          return '';
        }
      }
    } else {
      value = row[column.id];
    }

    if (column.mapping) {
      value = column.mapping[value];
    }

    switch (column.type) {
      case 'custom':
        return column.component ? column.component(row) : '';
      case 'numeric':
        return value;
      case 'currency':
        return '€ ' + value;
      case 'date':
        return toDutchString(new Date(value));
      case 'string':
      default:
        return '' + (value || '');
    }
  };

  const onAddClick = () => {
    if (props.onAddClick) {
      props.onAddClick();
    } else {
      history.push((props.link || props.url) + '/new/edit');
    }
  };

  const onExportClick = async () => {
    let fetching = true;
    try {
      setTimeout(() => {
        if (fetching) {
          setLoading(true);
        }
      }, 400);

      const result = await api.get(props.url, {
        page: 0,
        pageSize: 5000,
        sortOrder: order,
        sortBy: orderBy,
        search: searchText,
        include: props.include || '',
        orFilters: filters
          .filter((f) => f.active)
          .map((f) => ({ value: f.value, field: f.field }))
          .concat(props.queryOrFilters || []),
        andFilters: props.queryAndFilters,
      });
      if (result && result.data.data[0]) {
        let items = result.data.data;

        if (selected.length > 0) {
          items = items.filter((i: any) => selected.indexOf(i.id) >= 0);
        }

        download(
          props.columns.map((column, cellIndex) => column.label).join(',') +
            '\r\n' +
            items
              .map((item: any) =>
                props.columns
                  .map((column, cellIndex) => `"${getCellValue(column, item)}"`)
                  .join(','),
              )
              .join('\r\n'),
          'export.csv',
          'text/csv',
        );
        // download(
        //   props.columns.map((column, cellIndex) => column.label).join(',') +
        //     '\r\n' +
        //     props.columns.map((column, cellIndex) => 'test').join(','),
        //   'export.csv',
        //   'text/csv',
        // );

        // download(
        //   Object.keys(items[0]).join(',') +
        //     '\r\n' +
        //     items
        //       .map((row: any) =>
        //         Object.keys(row)
        //           .map((col: any) => '"' + (row[col] || '') + '"')
        //           .join(','),
        //       )
        //       .join('\r\n'),
        //   'export.csv',
        //   'text/csv',
        // );

        // download(
        //   Object.keys(items[0]).join(',') +
        //     '\r\n' +
        //     items
        //       .map((row: any) =>
        //         Object.keys(row)
        //           .map((col: any) => '"' + (row[col] || '') + '"')
        //           .join(','),
        //       )
        //       .join('\r\n'),
        //   'export.csv',
        //   'text/csv',
        // );
      }
    } finally {
      fetching = false;
      setLoading(false);
    }
  };

  // Determine if colors should be set
  const colorsColumn = props.columns.find((c) => !!c.colors);

  return (
    <>
      <Dialog
        open={openDeleteDialog}
        onClose={() => {
          setOpenDeleteDialog(false);
        }}
      >
        <DialogTitle>{t('form.delete_confirmation_title', { text: t('selected') })}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('form.delete_confirmation_text', { text: t('selected') })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDeleteDialog(false);
            }}
            color="primary"
          >
            {t('Cancel')}
          </Button>
          <Button
            onClick={() => {
              setOpenDeleteDialog(false);
              deleteSelected();
            }}
            color="primary"
            autoFocus={true}
          >
            {t('OK')}
          </Button>
        </DialogActions>
      </Dialog>
      <div className={(classes as any).root}>
        <Paper className={(classes as any).paper}>
          <TableToolbar
            numSelected={selected.length}
            selectedIds={selected}
            title={t(props.title)}
            searchText={searchText}
            onSearchChanged={(text) => {
              setSearchText(text);
              setPage(0);
            }}
            filters={filters}
            onFilterChanged={(filter) => {
              const currentFilter = filters.find((f) => f === filter);
              if (currentFilter) {
                currentFilter.active = !currentFilter.active;
              }
              setFilters([...filters]);
            }}
            onDelete={() => handleDelete()}
            selectActions={props.selectActions}
            hideDelete={props.disableDelete}
          />
          <div className={(classes as any).tableWrapper}>
            <MuiTable
              className={(classes as any).table}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <TableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rowCount}
                columns={props.columns}
                showSelect={props.showSelect}
              />
              <TableBody>
                {!loading &&
                  rows.map((row: any, index: any) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    // Check if colors need to be set in one of the child columns
                    let rowColor;
                    if (colorsColumn && colorsColumn.colors) {
                      rowColor = colorsColumn.colors.find(
                        (c) =>
                          c.value ===
                          getCellValue(
                            { ...colorsColumn, id: colorsColumn.colorField || colorsColumn.id },
                            row,
                          ),
                      );
                    }

                    return (
                      <TableRow
                        hover={true}
                        onClick={(event) => handleClick(event, row)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        className={(classes as any).row}
                        key={index}
                        selected={isItemSelected}
                        style={{ backgroundColor: rowColor ? rowColor.color : 'inherit' }}
                      >
                        {props.showSelect ? (
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ 'aria-labelledby': labelId }}
                              onClick={(event) => handleCheckboxClick(event, row)}
                            />
                          </TableCell>
                        ) : null}
                        {/* <TableCell padding="checkbox">
                      <Tooltip title="Edit">
                        <IconButton aria-label="Edit">
                          <Link to={'/customer/23'} component={RouterLink}>
                            <Icon>create</Icon>
                          </Link>
                        </IconButton>
                      </Tooltip>
                    </TableCell> */}
                        {props.columns.filter((c) => c.hide !== true).map((column, cellIndex) => (
                          <TableCell
                            className={(classes as any).cell}
                            key={cellIndex}
                            align={
                              ['numeric', 'date', 'currency'].includes(column.type || '')
                                ? 'right'
                                : 'left'
                            }
                          >
                            {getCellValue(column, row)}
                          </TableCell>
                        ))}
                        {/* <TableCell padding="checkbox">
                      <Tooltip title="Delete">
                        <IconButton aria-label="Delete">
                          <Icon>delete</Icon>
                        </IconButton>
                      </Tooltip>
                    </TableCell> */}
                      </TableRow>
                    );
                  })}
                {/* {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={props.columns.length + 1} />
                </TableRow>
              )} */}
                {!loading &&
                  rowCount === 0 && (
                    <TableRow>
                      <TableCell colSpan={props.columns.length + 1}>
                        <i>{t('No rows found')}</i>
                      </TableCell>
                    </TableRow>
                  )}
                {loading && (
                  <TableRow>
                    <TableCell colSpan={props.columns.length + 1}>
                      <LinearProgress />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </MuiTable>
            <div className={styles.footer}>
              <div>
                {!props.disableAdd && (
                  <Button variant="contained" color="primary" onClick={onAddClick}>
                    <Icon>add</Icon>
                  </Button>
                )}
                <Button variant="contained" color="default" onClick={onExportClick}>
                  <Icon>description</Icon>
                </Button>
              </div>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={rowCount}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </div>
          </div>
        </Paper>
      </div>
    </>
  );
};
