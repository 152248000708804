import React, { useContext } from 'react';

import {
  AppBar,
  Badge,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@material-ui/core';
import {
  AccountCircle,
  Mail as MailIcon,
  Menu as MenuIcon,
  More as MoreIcon,
  Notifications as NotificationsIcon,
  Search as SearchIcon,
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import useReactRouter from 'use-react-router';

import { AuthContext } from '../../features/auth/Auth';
import { SettingsContext } from '../../services/settings';
import { LanguagePicker } from '../LanguagePicker';
import { NavMenuContext } from './NavMenu';

import styles from './Header.module.scss';

export const Header: React.FC<{}> = () => {
  const { history } = useReactRouter();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const { t } = useTranslation();
  const settings = useContext(SettingsContext);

  const { isNavMenuOpen, toggleNavMenu } = useContext(NavMenuContext);
  const auth = useContext(AuthContext);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  function handleProfileMenuOpen(event: any) {
    setAnchorEl(event.currentTarget);
  }

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }

  function handleProfileItemClick() {
    if (auth.user) {
      history.push('/user/' + auth.user.id);
    }
  }

  function handleMenuClose() {
    setAnchorEl(null);
    handleMobileMenuClose();
  }

  function handleLogout() {
    setAnchorEl(null);
    auth.logout();
  }

  function handleMobileMenuOpen(event: any) {
    setMobileMoreAnchorEl(event.currentTarget);
  }

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted={true}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleProfileItemClick}>{t('Profile')}</MenuItem>
      <MenuItem onClick={handleLogout}>{t('Logout')}</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted={true}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileItemClick}>{t('Profile')}</MenuItem>
      <MenuItem onClick={handleLogout}>{t('Logout')}</MenuItem>
    </Menu>
  );

  return (
    <div>
      <AppBar position="absolute" className={styles.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            className={styles.menuButton}
            color="inherit"
            onClick={toggleNavMenu}
          >
            <MenuIcon />
          </IconButton>
          <Typography className={styles.title} variant="h6" noWrap={true}>
            WETAC Pro
          </Typography>
          <div className={styles.search}>
            <div className={styles.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder={t('Search') + '…'}
              classes={{
                root: styles.inputRoot,
                input: styles.inputInput,
              }}
              inputProps={{ 'aria-label': 'Search' }}
            />
          </div>

          <div className={styles.sectionDesktop}>
            {/* <IconButton color="inherit">
              <Badge badgeContent={settings.notificationCount} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
            <LanguagePicker />
            <Typography className={styles.displayName} variant="caption" noWrap={true}>
              {auth.user ? auth.user.name : ''}
            </Typography>
            <IconButton
              edge="end"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </div>
          <div className={styles.sectionMobile}>
            <IconButton
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
};
