import React, { createContext, useState } from 'react';
import { IUser } from '../../models/IUser';
import { api } from '../../services/api';

export interface IAuthState {
  isAuthenticated: boolean;
  token: string | null;
  user: IUser | null;
}
export interface IAuthContext extends IAuthState {
  login: (email: string, password: string) => any;
  logout: () => void;
  reset: (email: string) => void;
}

export const AuthContext = createContext<IAuthContext>({
  isAuthenticated: false,
  token: null,
  user: null,
  login: (email: string, password: string) => {
    // ..
  },
  logout: () => {
    // ..
  },
  reset: () => {
    // ..
  },
});

export const AuthContextProvider: React.FC<{}> = (props) => {
  let initialAuthState = {
    isAuthenticated: false,
    token: null,
    user: null,
  };

  // Get auth state from localstorage
  const authStateJson = localStorage.getItem('AUTH_STATE');
  if (authStateJson) {
    initialAuthState = JSON.parse(authStateJson);
  }

  const login = async (email: string, password: string) => {
    const result = await api.post('/auth/login', { email, password });
    if (result && result.status === 200 && result.data.token) {
      const newAuthState = {
        isAuthenticated: true,
        token: result.data.token,
        user: result.data.user,
      };
      localStorage.setItem('AUTH_STATE', JSON.stringify(newAuthState));
      setAuthState(newAuthState);
    }

    return result;
  };

  const logout = async () => {
    try {
      const result = await api.get('/auth/logout');
    } catch (err) {
      // ..
    }
    localStorage.removeItem('AUTH_STATE');
    setAuthState({ isAuthenticated: false, token: null, user: null });
  };

  const reset = async (email: string) => {
    await api.post('/auth/reset', { email });
  };

  const [authState, setAuthState] = useState<IAuthState>(initialAuthState);
  return (
    <AuthContext.Provider
      value={{
        ...authState,
        login,
        logout,
        reset,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export const Auth: React.FC<{}> = (props) => {
  return (
    <>
      <h1>AUTH</h1>
    </>
  );
};
