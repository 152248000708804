import React from 'react';

import { ViewFormBase } from '../../components/form/ViewFormBase';
import { MenuItems } from '../../components/layout/NavMenu';

export const ProductDetail: React.FC<{}> = (props) => {
  return (
    <>
      <ViewFormBase
        name="productGroup"
        url="/productGroup"
        idParam="productGroupId"
        breadcrumbs={[MenuItems.Products]}
        fields={[
          { label: 'Name', field: 'name', col: 12, translatable: true },
          { label: 'Code', field: 'code', col: 12 },
        ]}
      />
    </>
  );
};
