import React from 'react';

import { useTranslation } from 'react-i18next';

import { ViewFormBase } from '../../components/form/ViewFormBase';
import { MenuItems } from '../../components/layout/NavMenu';

export const ScanDetail: React.FC<{}> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <ViewFormBase
        url="/scan"
        name="scan"
        idParam="scanId"
        breadcrumbs={[MenuItems.Scans]}
        fields={[
          {
            label: 'Customer',
            field: 'name',
            col: 12,
          },
          {
            label: 'Type',
            field: 'type',
            col: 12,
            mapping: {
              1: t('Incoming'),
              2: t('Outgoing'),
            },
          },
          { label: 'Code', field: 'code', col: 12 },
        ]}
      />
    </>
  );
};
