import React, { createContext, useState } from 'react';

export interface ISettings {
  languageCode: string;
  notificationCount: number;
  translationReturnUrl?: string;
  translationReturnName?: string;
}
export interface ISettingsContext extends ISettings {
  updateSettings: (settings: Partial<ISettings>) => void;
}

export const SettingsContext = createContext<ISettingsContext>({
  languageCode: 'en',
  notificationCount: 4,
  updateSettings: (settings) => {
    // ..
  },
});

let initialSettingsState = {
  languageCode: 'en',
  notificationCount: 0,
};

// Get settings state from localstorage
const settingsStateJson = localStorage.getItem('SETTINGS_STATE');
if (settingsStateJson) {
  initialSettingsState = JSON.parse(settingsStateJson);
}

export const SettingsContextProvider: React.FC<{}> = (props) => {
  const [settings, setSettings] = useState<ISettings>(initialSettingsState);

  const updateSettings = async (newSettings: Partial<ISettings>) => {
    const newSettingsState = { ...settings, ...newSettings };
    localStorage.setItem('SETTINGS_STATE', JSON.stringify(newSettingsState));
    setSettings(newSettingsState);
  };

  return (
    <SettingsContext.Provider
      value={{
        ...settings,
        updateSettings,
      }}
    >
      {props.children}
    </SettingsContext.Provider>
  );
};
