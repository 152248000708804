import React from 'react';

import countryList from 'country-list';

import { EditFormBase } from '../../components/form/EditFormBase';
import { MenuItems } from '../../components/layout/NavMenu';

export const CustomerEdit: React.FC<{}> = (props) => {
  return (
    <>
      <EditFormBase
        url="/customer"
        name="customer"
        idParam="customerId"
        breadcrumbs={[MenuItems.Customers]}
        fields={[
          { label: 'Name', field: 'name', required: true, col: 12 },
          { label: 'Address', field: 'address', col: 12 },
          // { label: 'Housenumber', field: 'houseNumber', col: 3 },
          // { label: 'Addition', field: 'houseNumberAddition', col: 2 },
          { label: 'Postal code', field: 'postalCode', col: 3 },
          { label: 'City', field: 'city', col: 3 },
          {
            label: 'Country',
            field: 'country',
            col: 6,
            type: 'select',
            options: countryList.getData().map((country) => {
              return { label: country.name, value: country.name };
            }),
          },
          { label: 'Contact person', field: 'contact', col: 6 },
          { label: 'Email', field: 'email', type: 'email', col: 6 },
          { label: 'Phone', field: 'phone', col: 6 },
          { label: 'Customer ID', field: 'wetacCustomerId', required: true, col: 6 },
        ]}
      />
    </>
  );
};
