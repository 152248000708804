import React from 'react';

import { ViewFormBase } from '../../components/form/ViewFormBase';
import { MenuItems } from '../../components/layout/NavMenu';

export const TranslationDetail: React.FC<{}> = (props) => {
  return (
    <>
      <ViewFormBase
        name="translation"
        url="/translation"
        idParam="translationId"
        breadcrumbs={[MenuItems.Translations]}
        fields={[
          { label: 'Value', field: 'value', col: 12 },
          { label: 'Language', field: 'languageCode', col: 12 },
        ]}
      />
    </>
  );
};
