import React from 'react';

import useReactRouter from 'use-react-router';

import { ViewFormBase } from '../../components/form/ViewFormBase';
import { MenuItems } from '../../components/layout/NavMenu';

export const ProductQuestionDetail: React.FC<{}> = (props) => {
  return (
    <>
      <ViewFormBase
        name="productQuestion"
        url="/claimProductQuestion"
        idParam="claimProductQuestionId"
        breadcrumbs={[MenuItems.ProductQuestions]}
        include={'productGroup'}
        fields={[
          {
            label: 'Productgroup',
            field: 'productGroup.name',
            col: 6,
          },
          { label: 'Question', field: 'question', col: 12, translatable: true },
          { label: 'Info', field: 'info', col: 12, translatable: true },
          {
            label: 'Type',
            field: 'type',
            col: 6,
          },
          { label: 'Order', field: 'order', type: 'number', col: 3 },
          { label: 'Required', field: 'required', type: 'boolean', col: 3 },
        ]}
      />
    </>
  );
};
