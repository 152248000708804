import React from 'react';

import useReactRouter from 'use-react-router';

import { ViewFormBase } from '../../components/form/ViewFormBase';
import { MenuItems } from '../../components/layout/NavMenu';
import { Table } from '../../components/table/Table';
import theme from '../../utils/Theme';

export const DashboardClaimDetail: React.FC<{}> = (props) => {
  const { match } = useReactRouter();

  const statuses = {
    in_progress: theme.colors.warning.light,
    rejected: theme.colors.error.light,
    processed: theme.colors.success.light,
  };

  return (
    <>
      <ViewFormBase
        url="/dashboard/claim"
        name="Claim"
        include="claimStatus,customer"
        idParam="claimId"
        breadcrumbs={[MenuItems.Dashboard]}
        disableDelete={true}
        disableEditing={true}
        fields={[
          { field: 'claimNr', label: 'Claim #', col: 6 },
          {
            field: 'customer.name',
            label: 'Customer',
            col: 6,
            component: (entity: any) => (
              <a href={`/customer/${entity.customerId}`}>{entity.customer.name}</a>
            ),
          },
          { field: 'requesterName', label: 'Requester', col: 6 },
          { field: 'emailAddress', label: 'E-mail', col: 6 },
          { field: 'jobTitle', label: 'Job title', col: 6 },
          { field: 'companyName', label: 'Company', col: 6 },
          { field: 'companyLocation', label: 'Location', col: 6 },
          { field: 'country', label: 'Country', col: 6 },
          { field: 'remarks', label: 'Remarks', col: 6 },
          {
            field: 'claimStatus.name',
            label: 'Status',
            col: 6,
            component: (entity) => (
              <div
                style={{
                  backgroundColor: (statuses as any)[entity.claimStatus.code],
                  display: 'inline-block',
                  borderRadius: '12px',
                  fontSize: '14px',
                  padding: '4px',
                  paddingLeft: '8px',
                  paddingRight: '8px',
                }}
              >
                {entity.claimStatus.name}
              </div>
            ),
          },
        ]}
      />
      <Table
        url={`/dashboard/claim/${(match.params as any).claimId}/product`}
        // link={`/customer/${(match.params as any).customerId}/claim/${
        //   (match.params as any).claimId
        // }/product`}
        include={'productGroup'}
        defaultSortColumn={'type'}
        defaultSortOrder={'desc'}
        disableAdd={true}
        headers={[]}
        title={'Products'}
        columns={[
          { id: 'code', label: 'Product code' },
          { id: 'productGroup.name', label: 'Product group' },
          { id: 'type', label: 'Type' },
          { id: 'orderNumber', label: 'Order Number' },
          { id: 'productionDate', label: 'Production Date', type: 'date' },
          { id: 'sequenceNumber', label: 'Sequence Number' },
          { id: 'createdAt', label: 'Created At', type: 'date' },
        ]}
        onClick={() => {}}
      />
    </>
  );
};
