import React from 'react';

import useReactRouter from 'use-react-router';

import { EditFormBase } from '../../components/form/EditFormBase';
import { MenuItems } from '../../components/layout/NavMenu';

export const RegistrationEdit: React.FC<{}> = (props) => {
  const { match } = useReactRouter();
  return (
    <>
      <EditFormBase
        url="/registration"
        name="registration"
        link={`/customer/${(match.params as any).customerId}`}
        idParam="registrationId"
        breadcrumbs={[MenuItems.CustomerDetail]}
        fields={[
          { label: 'Code', field: 'code', col: 12, required: true },
          {
            label: 'Productgroup',
            field: 'productGroupId',
            required: true,
            col: 6,
            type: 'select',
            optionsUrl: '/productGroup',
            optionsValueField: 'id',
            optionsLabelField: 'name',
          },
          { label: 'Type', field: 'type', col: 12 },
          { label: 'Order Number', field: 'orderNumber', col: 12 },
          { label: 'Production Date', field: 'productionDate', col: 12 },
          { label: 'Sequence Number', field: 'sequenceNumber', col: 12, type: 'number' },
          { label: 'Reference 1', field: 'reference2', col: 12 },
          { label: 'Reference 2', field: 'reference1', col: 12 },
        ]}
      />
    </>
  );
};
