import React from 'react';

import { EditFormBase } from '../../components/form/EditFormBase';
import { MenuItems } from '../../components/layout/NavMenu';

export const ProductEdit: React.FC<{}> = (props) => {
  return (
    <>
      <EditFormBase
        url="/productGroup"
        name="productgroup"
        idParam="productGroupId"
        breadcrumbs={[MenuItems.Products]}
        fields={[
          { label: 'Name', field: 'name', required: true, col: 12 },
          { label: 'Code', field: 'code', required: true, col: 12 },
        ]}
      />
    </>
  );
};
