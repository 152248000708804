import React, { useContext, useState } from 'react';

import { AppBar, Toolbar } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { LanguagePicker } from '../../components/LanguagePicker';
import { AuthContext } from './Auth';

import '../../services/i18n';

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  } as any,
  paperWrap: {
    display: 'flex',
    alignItems: 'center',
    height: 'calc(100vh)',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  title: {
    display: 'block',
    flexGrow: 1,
  },
}));

export const ForgotPassword: React.FC<{}> = (props) => {
  const classes = useStyles({});
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showMessage, setShowMessage] = useState(false);

  const MadeWithLove = () => {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        WETAC Pro {t('is created by')}{' '}
        <Link color="inherit" href="https://wetac.nl/">
          WETAC Motive Power
        </Link>
      </Typography>
    );
  };
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <AppBar position="absolute">
        <Toolbar>
          <Typography className={classes.title} variant="h6" noWrap={true}>
            WETAC Pro
          </Typography>
          <LanguagePicker />
        </Toolbar>
      </AppBar>
      <div className={classes.paperWrap}>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t('Forgot password?')}
          </Typography>
          {showMessage ? (
            <div>
              <p>{t('password_reset_send')}</p>
              <Button
                fullWidth={true}
                variant="contained"
                color="primary"
                className={classes.submit}
                href="/"
              >
                {t('Back')}
              </Button>
            </div>
          ) : (
            <form className={classes.form} noValidate={true}>
              <TextField
                variant="outlined"
                margin="normal"
                required={true}
                fullWidth={true}
                id="email"
                label={t('Email address')}
                name="email"
                autoComplete="email"
                autoFocus={true}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button
                fullWidth={true}
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => {
                  auth.reset(email);
                  setShowMessage(true);
                }}
              >
                {t('Send reset link')}
              </Button>
            </form>
          )}
          <Box mt={5}>
            <MadeWithLove />
          </Box>
        </div>
      </div>
    </Container>
  );
};
