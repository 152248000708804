import React from 'react';

import { useTranslation } from 'react-i18next';

import { Table } from '../../components/table/Table';

export const Users: React.FC<{}> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <Table
        url={'/user'}
        headers={[]}
        title={'Users'}
        columns={[
          { id: 'name', label: 'Name' },
          { id: 'email', label: 'Email' },
          {
            id: 'role',
            label: 'Role',
            mapping: {
              customer: t('Customer'),
              administrator: t('Administrator'),
              superuser: t('Superuser'),
            },
          },
          { id: 'createdAt', label: 'Created At', type: 'date' },
          { id: 'updatedAt', label: 'Updated At', type: 'date' },
        ]}
      />
    </>
  );
};
