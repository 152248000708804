import React from 'react';

import useReactRouter from 'use-react-router';

import { useTranslation } from 'react-i18next';
import { EditFormBase } from '../../components/form/EditFormBase';
import { MenuItems } from '../../components/layout/NavMenu';

export const TranslationEdit: React.FC<{}> = (props) => {
  const { match } = useReactRouter();
  const { t } = useTranslation();

  return (
    <>
      <EditFormBase
        url="/translation"
        name="translation"
        idParam="translationId"
        breadcrumbs={[MenuItems.Translations]}
        showDelete={true}
        fields={[
          { field: 'entityTable', value: (match.params as any).entityTable, type: 'hidden' },
          {
            field: 'entityId',
            value: parseInt((match.params as any).entityId, 10),
            type: 'hidden',
          },
          { field: 'entityField', value: (match.params as any).entityField, type: 'hidden' },
          { label: 'Value', field: 'value', required: true, col: 12 },
          {
            label: 'Language',
            field: 'languageCode',
            required: true,
            col: 12,
            type: 'select',
            options: [
              { label: t('translations.nl'), value: 'nl' },
              { label: t('translations.en'), value: 'en' },
              { label: t('translations.fr'), value: 'fr' },
              { label: t('translations.de'), value: 'de' },
            ],
          },
        ]}
      />
    </>
  );
};
