import React, { useContext } from 'react';

import { Button, Icon } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useReactRouter from 'use-react-router';

import { Table } from '../../components/table/Table';
import { SettingsContext } from '../../services/settings';

export const Translations: React.FC<{}> = (props) => {
  const { match, history } = useReactRouter();
  const { t } = useTranslation();
  const settings = useContext(SettingsContext);

  return (
    <>
      {settings.translationReturnUrl && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (settings.translationReturnUrl) {
              history.push(settings.translationReturnUrl);
            }
          }}
        >
          <Icon className={`fa fa-arrow-circle-left`} style={{ marginRight: '0.5em' }} />{' '}
          {t('translations.back', { name: settings.translationReturnName })}
        </Button>
      )}
      <Table
        url={`/translation`}
        link={`/translation/${(match.params as any).entityTable}/${
          (match.params as any).entityId
        }/${(match.params as any).entityField}`}
        defaultSortColumn={'value'}
        defaultSortOrder={'asc'}
        headers={[]}
        queryAndFilters={[
          { field: 'entityTable', value: (match.params as any).entityTable },
          { field: 'entityField', value: (match.params as any).entityField },
          { field: 'entityId', value: (match.params as any).entityId },
        ]}
        title={t('translations.title', { name: (match.params as any).currentValue })}
        columns={[{ id: 'value', label: 'Translation' }, { id: 'languageCode', label: 'Language' }]}
        onClick={(row) => {
          history.push(
            `/translation/${(match.params as any).entityTable}/${(match.params as any).entityId}/${
              (match.params as any).entityField
            }/${row.id}/edit?return=${match.url}`,
          );
        }}
        onAddClick={() => {
          history.push(
            `/translation/${(match.params as any).entityTable}/${(match.params as any).entityId}/${
              (match.params as any).entityField
            }/new/edit?return=${match.url}`,
          );
        }}
      />
    </>
  );
};
