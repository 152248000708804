import React from 'react';

import { useTranslation } from 'react-i18next';

import { EditFormBase } from '../../components/form/EditFormBase';
import { MenuItems } from '../../components/layout/NavMenu';

export const UserEdit: React.FC<{}> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <EditFormBase
        url="/user"
        name="user"
        idParam="userId"
        breadcrumbs={[MenuItems.Users]}
        fields={[
          { label: 'Name', field: 'name', required: true, col: 12 },
          { label: 'Email', field: 'email', required: true, type: 'email', col: 12 },
          {
            label: 'Role',
            field: 'role',
            required: true,
            col: 12,
            type: 'select',
            options: [
              { label: t('Customer'), value: 'customer' },
              { label: t('Administrator'), value: 'administrator' },
              { label: t('Superuser'), value: 'superuser' },
            ],
          },
          {
            label: 'Password',
            field: 'password',
            required: true,
            type: 'password',
            col: 12,
            show: 'new',
          },
          {
            label: 'New password',
            field: 'updatePassword',
            required: false,
            type: 'password',
            col: 12,
            show: 'edit',
          },
        ]}
      />
    </>
  );
};
