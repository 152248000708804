import React, { useContext } from 'react';

import { Avatar, IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { SettingsContext } from '../services/settings';
import styles from './LanguagePicker.module.scss';

const languages = [
  {
    name: 'English',
    code: 'en',
  },
  {
    name: 'Nederlands',
    code: 'nl',
  },
  {
    name: 'Deutsch',
    code: 'de',
  },
  {
    name: 'Français',
    code: 'fr',
  },
];

export const LanguagePicker: React.FC<{}> = () => {
  const [languageMenuAnchorEl, setLanguageMenuAnchorEl] = React.useState(null);
  const settings = useContext(SettingsContext);
  const { i18n } = useTranslation();

  const handleLanguageMenuOpen = (event: any) => {
    setLanguageMenuAnchorEl(event.currentTarget);
  };
  const closeLanguageMenu = () => {
    setLanguageMenuAnchorEl(null);
  };
  const onLanguageClick = (languageCode: string) => {
    settings.updateSettings({ languageCode });
    i18n.changeLanguage(languageCode);
    closeLanguageMenu();
  };

  return (
    <>
      <IconButton color="inherit" className={styles.language} onClick={handleLanguageMenuOpen}>
        <Typography variant="button">{settings.languageCode.toUpperCase()}</Typography>
      </IconButton>
      <Menu
        anchorEl={languageMenuAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        keepMounted={true}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(languageMenuAnchorEl)}
        onClose={closeLanguageMenu}
      >
        {languages.map((language) => (
          <MenuItem
            key={language.code}
            onClick={() => {
              onLanguageClick(language.code);
            }}
          >
            <Avatar
              alt={language.name}
              src={`/images/flags/${language.code}.png`}
              className={styles.flag}
            />
            <p>{language.name}</p>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
