import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react';

import { FormSelect } from './FormSelect';
import { FormText } from './FormText';

export interface IFormInput {
  field: string;
  label?: string;
  type?:
    | 'text'
    | 'select'
    | 'multiline'
    | 'number'
    | 'password'
    | 'email'
    | 'boolean'
    | 'hidden'
    | 'date';
  value?: any;
  col?: number;
  show?: string;
  required?: boolean;
  options?: Array<{ label: string; value: any }>;
  optionsUrl?: string;
  optionsValueField?: string;
  optionsLabelField?: string;
  readonly?: boolean;
}

export interface IValidationState {
  dirty: boolean;
  valid: boolean;
  message: string;
}

export const FormComponent: React.FC<{
  field: IFormInput;
  value: any;
  validation: IValidationState;
  onChange?: (value: any) => void;
}> = (props) => {
  const handleChange = (value: any) => {
    if (props.onChange) {
      props.onChange(value);
    }
  };

  return (
    <Grid item={true} sm={(props.field.col as any) || 12}>
      {(!props.field.type ||
        props.field.type === 'text' ||
        props.field.type === 'email' ||
        props.field.type === 'password' ||
        props.field.type === 'multiline' ||
        props.field.type === 'number') && (
        <FormText
          field={props.field}
          value={props.field.type === 'number' ? parseFloat(props.value) : props.value}
          onChange={handleChange}
          validation={props.validation}
          multiline={props.field.type === 'multiline'}
          number={props.field.type === 'number'}
          password={props.field.type === 'password'}
        />
      )}
      {props.field.type === 'select' && (
        <FormSelect
          field={props.field}
          value={props.value}
          onChange={handleChange}
          validation={props.validation}
        />
      )}
      {props.field.type === 'boolean' && (
        <FormSelect
          field={{
            ...props.field,
            options: [{ label: 'Ja', value: true }, { label: 'Nee', value: false }],
          }}
          value={!!props.value}
          onChange={handleChange}
          validation={props.validation}
        />
      )}
    </Grid>
  );
};
